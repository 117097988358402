import { Link } from "react-router-dom";
import { ScrollToTopOnce } from '../lib/components';

const Privacy = () => {
    return (
        <div className='relative md:w-full lg:w-4/5 md:max-w-[430px] lg:max-w-full mx-auto h-full pt-12 pb-14 px-6 Roboto text-black bg-white text-[14px]'>
            <ScrollToTopOnce />
            <p className='text-[22px] text-center font-bold'>FANSI GO售票系統隱私權政策</p>
            <p className='mt-3'>FANSI GO售票系統為龍獨斑股份有限公司 FANSI Me Inc.（以下稱「本公司」）所經營管理之售票系統服務（以下稱「本服務」），我們對於每位使用者（下稱「您」或「使用者」）的隱私權皆相當重視，有關使用者資料的蒐集、利用、處理將依本政策及中華民國個人資料保護法之規定，請您在使用本服務前，務必詳細閱讀本FANSI GO售票系統隱私權政策（以下稱「本政策」）。</p>
            <p className='text-lg font-bold mt-5'>A. 使用者資訊蒐集與使用</p>
            <div className='w-full pl-4'>
                <ol className='list-decimal out-inside'>
                    <li className='mt-3'>本公司係基於以下目的蒐集使用者個人資料：</li>
                    <ol className="ml-4" style={{ listStyleType: 'lower-alpha' }}>
                        <li className='mt-2'>提供販售活動票券之服務，包含識別使用者身分、完成購買活動票券交易、核對或通知交易及票務資訊、通知行銷活動。</li>
                        <li className='mt-2'>因提供販售活動票券之服務，而需提供使用者個人資料予主辦單位。</li>
                        <li className='mt-2'>客製化使用者可能有興趣之廣告，以及透過個人資料進行系統內部比對，作為改善或提供新產品、新服務、聯絡使用者與進行市場調查之基礎。</li>
                    </ol>
                    <li className='mt-3'>前項特定目的，法定編號包含：020代理與仲介業務、036存款與匯款、040行銷、063非公務機關依法定義務所進行個人資料之蒐集處理及利用、067信用卡、現金卡、轉帳卡或電子票證業務、069契約、類似契約或其他法律關係管理之事務、073政府資訊公開、檔案管理及應用、081個人資料之合法交易業務、090消費者、客戶管理與服務、091消費者保護、098商業與技術資訊、099國內外交流業務、110產學合作、111票券業務、118智慧財產權、光碟管理及其他相關行政、123貿易推廣及管理、132經營傳播業務、135資（通）訊服務、136資（通）訊與資料庫管理、137資通安全與管理、142運動、競技活動、143運動休閒業務、144電信及傳播監理、145僱用與服務管理、146圖書館、出版品管理、148網路購物及其他電子商務服務、150輔助性與後勤支援管理、152廣告或商業行為管理、153影視、音樂與媒體管理、157調查、統計與研究分析、158學生（員）（含畢、結業生）資料管理、159學術研究、160憑證業務管理、176其他自然人基於正當性目的所進行個人資料之蒐集處理、178其他財政收入、181其他經營合於營業登記項目或組織章程所定之業務、182其他諮詢與顧問服務。</li>
                </ol>
            </div>
            <p className='text-lg font-bold mt-5'>B. 個人資料之類別</p>
            <p className='mt-3'>本公司蒐集的個人資料範圍，法定編號包含：C001辨識個人者、C002辨識財務者、C003政府資料中之辨識者、C011個人描述、C012身體描述、C013習慣、C014個性、C035休閒活動及興趣、C036生活格調、C037慈善機構或其他團體之會員資格、C038職業、C081收入、所得、資產與投資、C091資料主體所取得之財貨或服務、C092資料主體提供之財貨或服務、C093財務交易、C101資料主體之商業活動、C102約定或契約、C131書面文件之檢索、C132未分類之資料。</p>
            <p className='text-lg font-bold mt-5'>C. 個人資料的取得</p>
            <div className='w-full pl-4'>
                <ol className='list-decimal out-inside'>
                    <li className='mt-3'>本公司將透過以下方式，蒐集上述的個人資料：</li>
                    <ol className="ml-4" style={{ listStyleType: 'lower-alpha' }}>
                        <li className='mt-2'>使用者透過Facebook或Google登入或直接於本服務註冊成為會員時，本公司將取得使用者之姓名、E-mail、性別資訊、出生日期、聯絡電話、地址等個人資料，以及使用者使用本服務之帳號、密碼。</li>
                        <li className='mt-2'>使用者透過本服務購買活動票券時，本公司將根據活動主辦單位之需求，要求使用者提供姓名、E-mail、性別資訊、出生日期、聯絡電話、地址等個人資料。</li>
                        <li className='mt-2'>本公司於您使用本服務時，系統會自動紀錄您所在之IP位置，並記錄您所瀏覽之網頁，與所使用之服務內容，同時會在電腦上設定與存取cookies。您可以透過設定您的個人電腦或上網設備，決定是否允許cookies技術的使用，若您選擇關閉cookies，可能會造成您使用本服務時存在不便利或部分功能限制的情況。</li>
                    </ol>
                    <li className='mt-3'>除上述情況外，本公司於您使用各項產品或服務、瀏覽網頁、回報使用意見，或是參加促銷廣告方案時，將依據您所進行之行動蒐集相關之個人資料，包含職業、通訊地址、連絡電話等，或是留下您的使用紀錄以備日後查詢。</li>
                </ol>
            </div>
            <p className='text-lg font-bold mt-5'>D. 個人資料利用之期間、地區、對象及方式</p>
            <div className='w-full pl-4'>
                <ol className='list-decimal out-inside'>
                    <li className='mt-3'>本公司將透過以下方式處理或利用個人資料：</li>
                    <ol className="ml-4" style={{ listStyleType: 'lower-alpha' }}>
                        <li className='mt-2'>若您有使用Facebook或其他社群服務，本公司可能透過相關社群服務系統設計，於取得您的同意後，將部分本服務資訊發布於您的社群活動資訊頁面，若您不同意該等訊息之發布，請您勿點選同意鍵，或於事後透過各該社群服務之會員機制，移除該等資訊或拒絕本服務繼續發布相關訊息。</li>
                        <li className='mt-2'>當您註冊成為本服務會員，即代表您同意收到任何本公司所提供之產品或服務相關的電子郵件，其內容可能包括服務說明、帳號管理資訊、電子報等，若您不希望收到本公司任何上述的通知或宣傳訊息，可隨時與本公司聯繫。</li>
                        <li className='mt-2'>您所提供的個人資料，將以數位方式處理，並作為使用本公司產品或服務的身分判別、款項支付或代收轉付等流程依據，並在蒐集目的的範圍內，作為提供本服務、執行市場調查、進行客製化廣告與宣傳，以及本公司開發新產品或服務的基礎。</li>
                        <li className='mt-2'>除法令另有規定者外，本公司僅於您所同意之本服務目的及與本服務相關連之範圍內使用您的個人資料，不會任意將您提供的個人資料轉供其他第三方或移作其他目的使用。但基於提供本服務目的及履行因本服務所生或與本服務有關之義務，本公司將提供您的個人資料予本公司之關係企業或其他合作夥伴。</li>
                    </ol>
                    <li className='mt-3'>本公司蒐集、處理與利用個人資料之期限，將以FANSI GO售票系統服務條款（以下稱「服務條款」）的效力為準，若經使用者向本公司合法終止服務條款，本公司將停止蒐集、處理或利用使用者之個人資料，並進行相關檔案之刪除作業。</li>
                    <li className='mt-3'>本公司蒐集、處理與利用個人資料之地區，將以本公司實際的業務營運地為限。所謂實際營運業務，係指本公司已透過網站或其他媒介管道，在當地有產品或服務上市的情況。</li>
                    <li className='mt-3'>本公司亦可能與第三方共用綜合性的非個人資料，所謂「綜合性非個人資料」是指記錄有關使用者的個人資料被分組、收集、重組後的資料，前述資料於經過處理後不再反應或指向某個特定可被辨識之使用者，上述「綜合性非個人資訊」之處理與利用，可能由本公司、本公司之關係企業或其他合作夥伴進行利用，亦可能於台灣或於台灣以外的地區發生。</li>
                </ol>
            </div>
            <p className='text-lg font-bold mt-5'>E. 個人資料的自主權</p>
            <div className='w-full pl-4'>
                <ol className='list-decimal out-inside'>
                    <li className='mt-3'>使用者可向本公司請求下列事項：</li>
                    <ol className="ml-4" style={{ listStyleType: 'lower-alpha' }}>
                        <li className='mt-2'>使用者可隨時申請查詢、複製、補充、更正或閱覽屬於自身的個人資料，若非本人或於本公司尚未查證您為本人前，您不得申請查詢、複製、請求閱覽或申請變動不屬於使用者本人帳號之個人資料。本公司於必要時，有權要求您提供相關個人資料及書面資訊，協助本公司處理上述作業。</li>
                        <li className='mt-2'>使用者可隨時要求停止蒐集、處理、利用或刪除屬於您的個人資料；但一經停止或刪除，您將無法繼續使用本公司之產品或服務，本公司與您的服務條款也將視為終止。</li>
                    </ol>
                    <li className='mt-3'>為符合法令要求，於妨害國家重大利益、妨害公務機關執行法定職務、妨害蒐集機關或第三人之重大利益時，本公司有權拒絕您的上述請求。</li>
                </ol>
            </div>
            <p className='text-lg font-bold mt-5'>F. 個人資料的安全保護</p>
            <div className='w-full pl-4'>
                <ol className='list-decimal out-inside'>
                    <li className='mt-3'>請妥善保管您的個人資料（例如註冊帳號及密碼等），如您於電腦、手機或其他行動裝置上使用本服務，請務必於使用結束後登出或關閉瀏覽器視窗，以避免您的個人資料遭盜用。</li>
                    <li className='mt-3'>為保護您的隱私，本公司將實施必要之技術與措施負責資訊安全。本公司對於必要接觸使用者個人資料之員工，僅提供有限度之使用權限，確保使用者個人資料能獲得最大的保護。</li>
                    <li className='mt-3'>本公司未經使用者同意，不會向第三人揭露任何使用者的個人資料，但下列情況，不在此限：</li>
                    <ol className="ml-4" style={{ listStyleType: 'lower-alpha' }}>
                        <li className='mt-2'>在法院、檢察機關、警察機關或法令之正式要求下，本公司得將必要之個人資料內容提供給政府機關。</li>
                        <li className='mt-2'>當本公司其他第三者購併或收購資產，導致經營權轉換時，本公司會將公告相關細節，且本公司所擁有之全部或部分使用者資訊，亦可能在經營權轉換的狀況下移轉給第三人，但第三人將繼受並繼續遵循本政策的相關規範。</li>
                        <li className='mt-2'>其他依法律規定所為之行為，或為保護本公司或第三人在法律上之權益而有必要時，就主張權益所為之行為。</li>
                    </ol>
                </ol>
            </div>
            <p className='text-lg font-bold mt-5'>G. 免責聲明</p>
            <p className='mt-3'>目前所有科技均無法確保網路上之資訊傳遞或交易流程，能達到100%的安全性，使用者在使用透過網路提供的產品或服務時，均應自行負擔一定程度的風險。本政策之規範範圍，僅及於本公司經營之網站，若您點選進入第三人網站之連結或廣告等等內容，應依各該經營者所定之隱私權政策規定處理，與本公司無涉。</p>
            <p className='text-lg font-bold mt-5'>H. 隱私權政策的修訂與效力</p>
            <div className='w-full pl-4'>
                <ol className='list-decimal out-inside'>
                    <li className='mt-3'>本公司將不定時更新本政策，並依循服務條款之方式進行變更，變更之內容應遵循中華民國相關法令。</li>
                    <li className='mt-3'>本政策構成服務條款的一部分。</li>
                    <li className='mt-3'>如果您對本公司的隱私權政策有任何問題，或欲申請個人資料的查詢、複製、補充、更正、閱覽，或欲停止蒐集以及刪除個人資料，請儘速與本公司聯絡（本公司聯絡信箱為 <Link to={'mailto:support@fansi.me'}><span className='text-[#00b9c4] underline'>support@fansi.me</span></Link> ）。</li>
                </ol>
            </div>
            <div className="text-center">
                <button className='mt-12 w-1/2 h-11 px-[20px] bg-[#484848] rounded-[25px]' onClick={() => {
                    window.location.href = window.location.origin + '/tickets/';
                }}>
                    <p className='my-auto text-lg text-white font-medium'>回到首頁</p>
                </button>
            </div>
        </div>)
}


export default Privacy;