import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Carousel from 'react-material-ui-carousel'

import { SVG, IMG } from '../lib/imgLib';
import { convertDateTime } from '../lib/utils';
import __, { LangConveter } from '../lib/gettext';
import { LazyImg } from '../lib/components';

import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const SkeletonCard = () => {
  return (
    <Box
      sx={{
        width: 300,
        borderRadius: 2,
        boxShadow: 3,
        overflow: 'hidden',
        backgroundColor: 'white',
      }}
    >
      {/* Image Placeholder */}
      <Skeleton variant="rectangular" width="100%" height={150} />

      {/* Content Placeholder */}
      <Box sx={{ p: 2 }}>
        {/* Title */}
        <Skeleton variant="text" width="60%" height={24} />
        {/* Subtitle */}
        <Skeleton variant="text" width="80%" height={20} />

        {/* Icon with Text */}
        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
          <Skeleton variant="circular" width={24} height={24} />
          <Skeleton variant="text" width="40%" />
        </Stack>

        {/* Button */}
        <Skeleton
          variant="rectangular"
          width="100%"
          height={36}
          sx={{ mt: 3, borderRadius: 1 }}
        />
      </Box>
    </Box>
  );
};

const SkeletonGrid = () => {
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 2,
        marginTop: 4,
        gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
      }}
    >
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
    </Box>
  );
};

const EventBlock = ({ event }: any) => {
  const navigator = useNavigate();
  let onGoing = true;
  let info = __('HP_EVENT_RUNNING');
  if (new Date().getTime() > new Date(event.eventEnd).getTime()) {
    onGoing = false;
    info = __('HP_EVENT_ENDED');
  }
  let url = '/';
  switch (event.type) {
    case 1:
      url = '/Tickets/events/' + event.id;
      break;
    case 2:
      url = '/' + event.id;
      break;
    case 3:
      url = '/merch/events/' + event.id;
      info = __('HP_MERCH_RUNNING');
      break;
  }

  function handleClick() {
    // if (!onGoing) return;
    navigator(url);
  }

  return (
    <div onClick={handleClick} className='relative rounded-xl bg-white cursor-pointer' style={{ boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.15)' }}>
      <div className='relative w-full h-0 pb-[52.5%]'>
        <LazyImg className='absolute top-0 w-full h-full rounded-t-xl ' src={event.bannerUrl} alt={'banner' + event.id} />
      </div>
      <div className='p-4 leading-tight'>
        <p className='Roboto text-gray-76 font-medium truncate '>{LangConveter.getLocalText(event.artistName)}</p>
        <p className='Roboto font-semibold text-gray-48 text-lg truncate'>{LangConveter.getLocalText(event.eventName)}</p>
        <div className='flex mt-2'>
          <SVG src='Calendar' className='mr-1 my-auto' sx={{ width: 16, height: 15, color: '#767676' }} />
          <span className='text-sm Roboto font-medium text-gray-48'>{convertDateTime(event.eventStart).date + ' - ' + convertDateTime(event.eventEnd).date}</span>
        </div>        
        <div className={'absolute top-0 left-0 m-3 rounded-full px-[10px] py-[6px] border flex ' + (onGoing ? ((event.type === 3 ? 'bg-quest-yellow border-white' : 'bg-white border-gray-b6') + ' text-gray-48') : 'bg-gray-48 text-gray-ed border-white')}>
          <IMG src={onGoing ? 'Hourglass2' : 'Clock'} className='w-4 h-4 my-auto mr-1' style={{color: onGoing ? '#484848':''}}/>
          <p>{info}</p>
        </div>        
      </div>
      <div className='w-full h-px bg-gray-ed' />
      {onGoing ? <div className='w-full p-4'>
        <button className='w-full bg-teal-fg h-9 px-4 lg:px-2 flex rounded-4xl flex justify-center'>
          <p className='text-lg text-white font-medium my-auto mr-1'>{__('FGT_Purchase_Now')}</p>
          <IMG src='ArrowRight' className='h-5 w-5 my-auto' />
        </button>
      </div> : null}
    </div>
  )
}

const Home = () => {
  let eventList = [
    {
      id: 'FireEx2024NA',
      type: 2,
      eventName: '滅火器2024北美巡迴',
      bannerUrl: 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Events/FireEx2024NA/FireEx_Home_Banner.jpg',
      artistName: '火氣音樂',
      eventStart: '2024-03-04 00:00:00-0400',
      eventEnd: '2024-03-24 02:59:59-0400',
      eventId: 4

    },
    {
      id: 'NeonOasis2024',
      type: 2,
      eventName: '2024 綠洲尋寶',
      bannerUrl: 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Events/NeonOasis/Oasis-Banner-1.jpg',
      artistName: '綠洲霓虹音樂祭',
      eventStart: '2024-01-19 00:00:00+0800',
      eventEnd: '2024-01-21 23:59:59+0800',
      eventId: 2
    },
    {
      id: 'FireballMaster2023',
      type: 2,
      eventName: '2023 火球祭',
      bannerUrl: 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI/Events/FireballMaster2023/fireball_master_logo.png',
      artistName: '火氣音樂',
      eventStart: '2023-11-25 00:00:00+0800',
      eventEnd: '2023-11-26 23:59:59+0800',
      eventId: 1
    },
  ]
  const [currEvent, setCurr] = useState<any[]>([]);
  const [passEvent, setPass] = useState<any[]>([]);
  const [currMerch, setMerch] = useState<any[]>([]);
  const [passMerch, setPassMerch] = useState<any[]>([]);
  const [maxHistory, setMaxHistory] = useState(3);
  const GET_EVENT_LIST = gql`
  query GetEventList {
      events:getFGEventList
      merchs:FGGetMerchEventList
  }`;
  const { loading } = useQuery(GET_EVENT_LIST, {
    onCompleted: ((data: any) => {
      if (data.events) {
        try {
          for (let i = 0; i < data.events.length; i++) {
            let newEvent = JSON.parse(data.events[i]);
            eventList.push({
              ...{ type: 1 },
              ...newEvent
            });
          }
        } catch (err) {
          console.warn(err)
        }
      }
      SortEvents(eventList)
      if (data.merchs) {
        try {
          let merchList = [];
          for (let i = 0; i < data.merchs.length; i++) {
            let newMerch = JSON.parse(data.merchs[i]);
            merchList.push({
              ...{ type: 3 },
              ...newMerch
            });
          }
          SortEvents(merchList, 'merch');
        } catch (err) {
          console.warn(err)
        }
      }
    })
  });

  function SortEvents(events: any[], type: string = 'event') {
    let curr = new Date().getTime();
    let currE: any[] = [];
    let passE: any[] = [];

    const topList = [210001]
    const topListHold: any[] = [];
    for (let i = 0; i < events.length; i++) {
      let event = events[i];           
      if (event.eventOnline && curr < new Date(event.eventOnline).getTime())  // not start sale yet, ignore        
        continue;

      if (topList.indexOf(event.id) > -1) {
        topListHold.push(event);
      } else {
        if (curr < new Date(event['eventEnd']).getTime()) {
          currE.push(event);
        } else {
          passE.push(event);
        }
      }
    }

    currE = currE.sort((a: any, b: any) => {
      let eventStartA = new Date(a['eventStart']).getTime();
      let eventStartB = new Date(b['eventStart']).getTime();
      return eventStartA < eventStartB ? -1 : 1;
    })

    currE = topListHold.concat(currE);

    passE = passE.sort((a: any, b: any) => {
      let eventEndA = new Date(a['eventEnd']).getTime();
      let eventEndB = new Date(b['eventEnd']).getTime();
      return eventEndA > eventEndB ? -1 : 1; //end later first
    })

    if (type === 'merch') {
      if (currE.length > 0) setMerch(currE);
      if (passE.length > 0) setPassMerch(passE);
    } else {
      if (currE.length > 0) setCurr(currE);
      if (passE.length > 0) setPass(passE);
    }
  }

  let divIndex = 0;
  let history = 0;
  let historyIdx = 0;

  return (
    <div className='relative w-full pb-8 lg:desktop-min-height'>
      <div className='w-full relative pt-20 pb-2 bg-cover bg-[url("https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Img/fansi-go-body-bg.jpg")]'
        style={{ minHeight: 220, backgroundSize: 'cover', backgroundImage: 'url(https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Img/home_banner_bg.png)' }}>        
        <div className='w-full section-lg'>
          <Carousel cycleNavigation={false} swipe={true} animation='slide' navButtonsAlwaysInvisible={true}>
            <div className='relative mx-auto w-5/6 lg:w-2/5'>
              <a target='_blank' rel="noreferrer noopener" href={'https://go.fansi.me/Tickets/events/210001'}>
                <div className='relative w-full h-0 pb-[51.4%]'>
                  <LazyImg className='absolute rounded-xl w-full h-full' src={'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Img/home_banner_4.png'} alt='banner' />
                </div>
              </a>
            </div>               
            <div className='relative mx-auto w-5/6 lg:w-2/5'>
              <a target='_blank' rel="noreferrer noopener" href={'https://go.fansi.me/Tickets/events/230001'}>
                <div className='relative w-full h-0 pb-[51.4%]'>
                  <LazyImg className='absolute rounded-xl w-full h-full' src={'https://fansi-static.s3.ap-southeast-1.amazonaws.com/mail-ad/2025-lepxbremen.jpg'} alt='banner' />
                </div>
              </a>
            </div>   
            <div className='relative mx-auto w-5/6 lg:w-2/5'>
              <a target='_blank' rel="noreferrer noopener" href={'https://go.fansi.me/collection/Tickets'}>
                <div className='relative w-full h-0 pb-[51.4%]'>
                  <LazyImg className='absolute rounded-xl w-full h-full' src={'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Img/home_banner_1.png'} alt='banner' />
                </div>
              </a>
            </div>
            <div className='relative mx-auto w-5/6 lg:w-2/5'>
              <a target='_blank' rel="noreferrer noopener" href={'https://go.fansi.me/collection/Tickets'}>
                <div className='relative w-full h-0 pb-[51.4%]'>
                  <LazyImg className='absolute rounded-xl w-full h-full' src={'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Img/home_banner_2.png'} alt='banner' />
                </div>
              </a>
            </div>
            <div className='relative mx-auto w-5/6 lg:w-2/5'>
              <a target='_blank' rel="noreferrer noopener" href={'https://go.fansi.me/collection/Tickets'}>
                <div className='relative w-full h-0 pb-[51.4%]'>
                  <LazyImg className='absolute rounded-xl w-full h-full' src={'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Img/home_banner_3.png'} alt='banner' />
                </div>
              </a>
            </div>
          </Carousel>
        </div>
      </div>      
      <div className='relative section-lg p-6 lg:px-24 xl:px-48'>
        <div className='w-full flex lg:mt-12'>
          <p className='text-gray-48 text-xl font-semibold mr-4'>{__('HP_EVENT_RECENT')}</p>
          <p className='text-gray-76 text-xs font-medium my-auto'>{__('HP_EVENT_RECENT_SUBTITLE')}</p>
        </div>
        {loading ?
          <>
            <SkeletonGrid />
            <SkeletonGrid />
          </> :
          <div className='w-full flex flex-wrap'>
          {currEvent.map((event: any) => {
            let pad_lg = 'lg:pr-6';
            switch (divIndex % 3) {
              case 1:
                pad_lg = 'lg:px-3';
                break;
              case 2:
                pad_lg = 'lg:pl-6';
                break;
            }
            return (
              <div className={'w-full mt-4 lg:mt-9 lg:w-1/3 ' + pad_lg} key={'event' + (divIndex++)} >
                <EventBlock event={event} />
              </div>
            )
          })}
          </div>}

        {currMerch.length > 0 ?
          <div className='w-full'>
            <div className='w-full flex lg:mt-12'>
              <p className='text-gray-48 text-xl font-semibold mr-4'>{__('HP_MERCH_RECENT')}</p>
              <p className='text-gray-76 text-xs font-medium my-auto'>{__('HP_MERCH_RECENT_SUBTITLE')}</p>
            </div>
            {loading ?
              <>
                <SkeletonGrid />
                <SkeletonGrid />
              </> :
              <div className='w-full flex flex-wrap'>
                {currMerch.map((event: any) => {
                  let pad_lg = 'lg:pr-6';
                  switch (divIndex % 3) {
                    case 1:
                      pad_lg = 'lg:px-3';
                      break;
                    case 2:
                      pad_lg = 'lg:pl-6';
                      break;
                  }
                  return (
                    <div className={'w-full mt-4 lg:mt-9 lg:w-1/3 ' + pad_lg} key={'event' + (divIndex++)} >
                      <EventBlock event={event} />
                    </div>
                  )
                })}
              </div>}
          </div> : ''}
        <div className='w-full flex mt-20'>
          <p className='text-gray-48 text-xl font-semibold mr-4'>{__('HP_EVENT_PASSED')}</p>
          <p className='text-gray-76 text-xs font-medium my-auto'>{__('HP_EVENT_PASSED_SUBTITLE')}</p>
        </div>
        {loading ?
          <SkeletonGrid /> :
          <div className='w-full flex flex-wrap'>
            {passEvent.map((event: any) => {
              history++
              if (history <= maxHistory) {
                let pad_lg = 'lg:pr-6';
                switch (historyIdx % 3) {
                  case 1:
                    pad_lg = 'lg:px-3';
                    break;
                  case 2:
                    pad_lg = 'lg:pl-6';
                    break;
                }
                return (
                  <div className={'w-full mt-4 lg:mt-9 lg:w-1/3 ' + pad_lg} key={'pass-event' + (historyIdx++)} >
                    <EventBlock event={event} />
                  </div>
                )
              }
              return null;
            })}
          </div>}
        {history > maxHistory ?
          <button onClick={() => setMaxHistory(9999)} className='mt-9 w-full border border-teal-fg rounded-lg h-9 flex justify-center'>
            <p className='my-auto text-teal-fg text-sm mr-2'>{__('Show_More') + __('HP_EVENT_PASSED')}</p>
            <IMG src='ArrowDownT' className='my-auto h-4 w-4' />
          </button> : ''}

        {passMerch.length > 0 ?
          <div className='w-full'>
            <div className='w-full flex lg:mt-12'>
              <p className='text-gray-48 text-xl font-semibold mr-4'>{__('HP_MERCH_PASSED')}</p>
              <p className='text-gray-76 text-xs font-medium my-auto'>{__('HP_MERCH_PASSED_SUBTITLE')}</p>
            </div>
            {loading ?
              <>
                <SkeletonGrid />
                <SkeletonGrid />
              </> :
              <div className='w-full flex flex-wrap'>
                {passMerch.map((event: any) => {
                  let pad_lg = 'lg:pr-6';
                  switch (divIndex % 3) {
                    case 1:
                      pad_lg = 'lg:px-3';
                      break;
                    case 2:
                      pad_lg = 'lg:pl-6';
                      break;
                  }
                  return (
                    <div className={'w-full mt-4 lg:mt-9 lg:w-1/3 ' + pad_lg} key={'event' + (divIndex++)} >
                      <EventBlock event={event} />
                    </div>
                  )
                })}
              </div>}
          </div> : ''}
      </div>
    </div>
  )
}

export default Home;