import { Operation } from "@apollo/client";
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import path from 'ramda/es/path';
import gql from 'graphql-tag';
import { Cookies } from 'react-cookie';
import qs from 'qs';
import { Web3 } from 'web3';
import __ from '../lib/gettext';

import { Button, error, Spinner, success } from './components';
import { IMG } from './imgLib';
import UserAuth, { SITE_COOKIE } from './userAuth';
import { convertPassword, IsInAppBrowser } from './utils';
import { useGoogleLogin } from '@react-oauth/google';

import { Amplify } from 'aws-amplify';
import { signInWithRedirect, fetchAuthSession, getCurrentUser } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'

export const amoyERC20 = '0x5ac4812E91fF17484631E4c3C3bF7F4f98de89aD'
export const amoyUSDC = '0x41E94Eb019C0762f9Bfcf9Fb1E58725BfB0e7582'
const ContractABI = [
    //transfer
    {
		"inputs": [
			{
				"internalType": "address",
				"name": "recipient",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
]

Amplify.configure({
  
    Auth: {
      Cognito: {
        loginWith: {
          oauth: {
            redirectSignIn: ["http://localhost:3000/login", "https://master--fansi-go.netlify.app/login", "https://go.fansi.me/login"],
            redirectSignOut: ["http://localhost:3000/login", "https://master--fansi-go.netlify.app/login", "https://go.fansi.me/login"],
            domain: "fansidev.auth.ap-southeast-1.amazoncognito.com",
            providers: ["Google"],
            scopes: ["email", "openid", "aws.cognito.signin.user.admin"],
            responseType: "code",
          },
        },
        userPoolId: "ap-southeast-1_LyD9UaiT9",
        userPoolClientId: "2mbpinf4i15qa89o097lceqjd4",
      },
    },
  });


export const withAuthState = (Comp: React.ElementType) => {
    class Wrapper extends React.Component {
        state = {
            authenticated: false,
            user: null,
            loading: true,
        };

        constructor(props: Readonly<{}>) {
            super(props);
            UserAuth.currentAuthenticatedUser()
                .then((user) => {
                    this.setState({ authenticated: true, user, loading: false });
                })
                .catch((err) => {
                    this.setState({ loading: false });
                });
        }

        handleAuthEvent = ({ event, data }: { event: string; data?: any; }) => {
            switch (event) {
                case 'signOut':
                    this.setState({
                        authenticated: false,
                        user: null,
                    });
                    break;
                case 'signIn':
                    this.setState({
                        authenticated: true,
                        user: data,
                    });
                    break;
                default:
                    break;
            }
        };

        render() {
            return <Comp {...this.props} {...this.state} />;
        }
    }

    return Wrapper;
};

function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

const EnsureLoggedIn = withRouter(
    (props: UserAuthenticatorProps) => {
        const LOADING = 'loading';
        const AUTHENTICATED = 'authenticated';
        const AUTH_FAILURE = 'auth_failure';
        const [authStatus, setAuthStatus] = useState(LOADING);

        useEffect(() => {
            let isSignedIn = props.authState === 'signedIn';
            if (authStatus !== AUTHENTICATED && authStatus !== AUTH_FAILURE)
                setAuthStatus(isSignedIn ? AUTHENTICATED : AUTH_FAILURE);
        }, [props.authState, authStatus, setAuthStatus]);

        if (authStatus === AUTH_FAILURE && props.authState === 'signedIn') {
            return <div />
        } else if (authStatus === AUTHENTICATED && props.authState === 'signedIn') {
            return props.children; // We could provide a loading state here
        }
        return null;
    },
);

type AUTH_STATE =
    | 'signIn'
    | 'signUp'
    | 'signedIn'
    | 'signedUp'
    | 'chkThirdParty'
    | 'confirmSignUp'
    | 'confirmSignIn';

interface UserAuthenticatorProps {
    authData?: any;
    children?: any;
    authState?: AUTH_STATE;
    forward?: string;
    url?: string;
    customeStyle?: string;
}

const THIRD_PARTY_LOGIN = gql`
    query ThirdPartyLogin(
        $email: String!
        $id: String!
        $source: String!
        $displayName: String!
        $avatar: String!
        ) {
        user: thirdPartyLogin(
            email: $email
            id: $id
            source: $source
            displayName: $displayName
            avatar: $avatar
        ) {
            accessToken
            tokenLife
        }
    }
`;

interface ThirdPartyLoginProps {
    email: string;
    id: string;
    source: string;
    displayName: string;
    avatar: string;
    ctlFail: any;
    ctlSuccess: any;
}

const ThirdPartyLoginCheck = ({ ...props }: ThirdPartyLoginProps) => {
    let email = props.email;
    let id = props.id;
    let source = props.source;
    let displayName = props.displayName;
    let avatar = props.avatar;

    const getLoginInfo = useQuery(THIRD_PARTY_LOGIN,
        {
            variables: {
                email: email,
                id: id,
                source: source,
                displayName: displayName,
                avatar: avatar,
            },
        }
    );

    if (getLoginInfo.loading) return <Spinner />;
    if (getLoginInfo.error) {
        error(getLoginInfo.error)
        props.ctlFail();
        return null;
    }
    if (
        getLoginInfo.data.user !== null &&
        getLoginInfo.data.user.accessToken !== null
    ) {
        const cookies = new Cookies();
        cookies.set(SITE_COOKIE, getLoginInfo.data.user, {
            path: '/',
            maxAge: getLoginInfo.data.user.tokenLife,
        });
        props.ctlSuccess();
        return null;
    } else {
        props.ctlFail();
        return null;
    }
};

export const UserAuthenticator = ({
    children,
    url,
    forward,
    ...props
}: UserAuthenticatorProps) => {
    const GET_USER_PROFILE = gql`
        query GetUserProfile {
            me {
                id
                email
                walletAddress
            }
            accountStatus: getUserAccountStatus
        }
        `;

    const [authState, setAuthState] = useState(props.authState);
    const [username, setUsername] = useState('');
    const [thirdPartyId, setThirdPartyId] = useState('');
    const [avatar, setAvatar] = useState('');
    const [thirdParty, setThirdParty] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [loading, setLoading] = useState(false);
    const [loginFail, setLoginFail] = useState(false);
    const [accountStatus, setAccountStatus] = useState(1);


    let getUserProfile: any;
    getUserProfile = useQuery(GET_USER_PROFILE, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            if (data && data.me) {
                setAccountStatus(parseInt(data.accountStatus + ''));
            }
        }
    })

    if (getUserProfile.loading) return <div className='absolute-center'><Spinner /></div>;
    const inAppBrowser = IsInAppBrowser();    
    const href = window.location.href.replace('#access_token', 'access_token');
    const currentUrl = new URL(href);
    let UpdAccount = currentUrl.searchParams.get("UpdAccount");
    let access_token = currentUrl.searchParams.get("access_token");
    let info;
    let state: any;
    if (access_token) {
        info = qs.parse(href);
        if (info && info['state']) {
            state = JSON.parse(info['state'] + '');
            if (state && state['UpdAccount']) UpdAccount = state['UpdAccount'];
        }
    }


    //檢查是否登入，已經登入就直接render
    if (UserAuth.isAuthenticate()) {
        if (!UpdAccount || UpdAccount !== 'YES' || accountStatus === 3)
            return <EnsureLoggedIn authState='signedIn'>{children}</EnsureLoggedIn>;
    } else if (url && url.indexOf('fund') !== -1) {
        let nextUrl = window.origin + '/login/?n=' + encodeURIComponent(url);
        window.location.href = nextUrl;
    }

    if (access_token && !loading && !loginFail) {
        if (state) {
            let forward = currentUrl.searchParams.get("n");
            if (!forward && state['forwardUrl'] && UpdAccount !== 'YES') {
                window.location.href = currentUrl + '&n=' + state['forwardUrl'];
            }
            if (state['status'] && state['status'] === 'login') {
                let current = Math.round(new Date().getTime() / 1000);
                if (state['chkTime'] && (current - parseInt(state['chkTime']) < 300)) { //only works for 5 minutes
                    if (state['chkCode'] && state['chkCode'] === convertPassword(state['chkTime'] + '')) { //check confirm code
                        setAvatar('NA');
                        setUsername('CHK_ACCESS_TOKEN');
                        setDisplayName('Facebook');
                        setThirdParty('Facebook');
                        setThirdPartyId(access_token);
                        setAuthState('chkThirdParty');
                        setLoading(true);
                    }
                }
            }
        }
    }

    const loginSuccess = () => {
        setAuthState('signedIn');
        props.authState = authState;
        if (state && state['forwardUrl']) {
            window.location.href = window.location.origin + state['forwardUrl'];
        } else {
            window.location.reload();
        }
    };

    const invalidLoginInfo = () => {
        setAuthState('signIn');
        setLoading(false);
        setLoginFail(true);
    };

    return (
        <div className='flex flex-col h-screen Roboto text-black'>
            <div className='relative mx-auto flex-1 w-full h-screen min-h-[600px] overflow-hidden bg-black pt-16 pb-48' >
                <img className='absolute w-full top-0 left-0 z-10' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Img/login_bg.jpg' alt='login' />
                <div className='relative w-full mt-56 z-30'>
                    <IMG src='FANSIGO_I' className='relative w-64 mx-auto' />
                    <p className='mt-6 text-center font-medium text-white'>{__('Header_Slogan')}</p>
                </div>
                {inAppBrowser ? <p className='relative mt-8 text-[#F97E64] text-center text-xl z-40'>請使用Chrome瀏覽器登入</p> :
                    <div className='absolute w-full bottom-0 z-40' style={{height:'40%'}}>
                        <div className='w-full px-14'>
                            <GoogleLogin />
                            <div className="my-6">
                                <AWSLogin />
                            </div>                            
                        </div>
                        <p className='mt-5 text-xs text-gray-b6 text-center whitespace-pre'>{`${__('Login_N_Agree')}`}
                            <Link to='/service' target='_blank'><span className='ml-1 text-[#00b9c4] underline'>{__('Terms_of_Service')}</span></Link>、
                            <Link to='/privacy' target='_blank'><span className='ml-1 text-[#00b9c4] underline'>{__('Privacy_Policy')}</span></Link>
                        </p>
                        {/* <div className="w-full horizon-center text-xs text-white flex justify-center" style={{bottom:20}}>
                            <SVG src='Info' className='my-auto mr-2' sx={{ width: 19, height: 19, color: '#ffffff' }} />
                            <p className='my-auto'>若遇登入註冊問題，煩請點選其他google帳號登入</p>
                        </div>                         */}
                    </div>}
                {authState === 'chkThirdParty' ? (
                    <div className='container text-center'>
                        <ThirdPartyLoginCheck
                            ctlFail={invalidLoginInfo}
                            ctlSuccess={loginSuccess}
                            email={username}
                            id={thirdPartyId}
                            source={thirdParty}
                            displayName={displayName}
                            avatar={avatar}
                        />
                    </div>
                ) : (
                    ''
                )}
            </div>
            <EnsureLoggedIn authState={authState}>{children}</EnsureLoggedIn>
        </div >
    );
};

export const withAuthentication = (Comp: React.ComponentType<{}>) => {
    return (props: object) => {
        const url: string = path(['match', 'url'], props) + '' || '';
        return (
            <UserAuthenticator url={url}>
                <Comp {...props} />
            </UserAuthenticator>
        );
    };
};

export const makeAuthenticatedRequest = (operation: Operation) => {
    return (
        UserAuth.currentAuthenticatedUser()
            .then((user) => {
                const session = user.signInUserSession;
                const token = session.getAccessToken();
                operation.setContext({
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                });
            })
            .catch((err) => {
                console.warn('[INFO]:01 User is not signed in. Will fetch without authentication.' + err.message);
            })
    );
};

const parseQuerystring = (x: string) => {
    x = x.replace(/^\?/, ''); // strip leading question mark
    return qs.parse(x);
};

const RedirectAfterLogin = () => {
    useEffect(() => {
        let nextUrl = '/';
        if (window.location.search) {
            const { n } = parseQuerystring(window.location.search);
            if (typeof n === 'string') nextUrl = n;
        }
        window.location.href = nextUrl;
    });

    return (
        <div className='container section'>
            <Spinner />
        </div>
    );
};

const GET_LOGIN_NONCE = gql`
query getLoginNonce($walletAddress: String!) {
    nonce: getLoginNonce(walletAddress: $walletAddress)
}
`;

export async function InitialWeb3() {
    if (!window.web3api || !window.web3api.eth || !window.ethereum) {
        try {
            if (window.ethereum) {
                window.web3api = new Web3(window.ethereum);
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
                window.ethereum.on("accountsChanged", () => {
                    error('Account Changed!')
                    window.location.reload();
                });
                if (accounts.length > 0) {
                    return 1;
                } else {
                    return 2;
                }
            } else {
                return 3;
            }
        } catch (error: any) {
            if (error.code === -32002) {
                const checkAccount = setInterval(async () => {
                    try {
                        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                        if (accounts.length > 0) {
                            clearInterval(checkAccount);
                            success('MetaMask unlock! reloading ')
                            window.location.reload();
                        }
                    } catch (err) {
                    }
                }, 2000)
            }
            console.warn(error);
            return 4;
        }
    }
    return 5;
}

export async function LoadContract(scAddress: string) {
    if (!window.web3api || !window.web3api.eth) return undefined;
    try {
        var contract = await new window.web3api.eth.Contract(ContractABI, scAddress);
        return contract;
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}

export const MMLoginBtn = ({ className, labelClass, size, label, hideIcon }: { className?: string, size?: string, label?: string, hideIcon?: boolean, labelClass?: string }) => {
    const prefix = 'Login MetaBoom with Metamask. Nonce:';
    const [MMLogin, setMMLogin] = useState(false);
    const [wallet, setWallet] = useState('');
    const [thirdPartyLogin] = useLazyQuery(THIRD_PARTY_LOGIN, {
        onCompleted: data => {
            if (data) {
                if (
                    data.user !== null &&
                    data.user.accessToken !== null
                ) {
                    const cookies = new Cookies();
                    cookies.set(SITE_COOKIE, data.user, {
                        path: '/',
                        maxAge: data.user.tokenLife,
                    });
                    window.location.reload();
                } else {
                    window.location.reload();
                }
            }
        },
        onError: (err: any) => {
            error(err.message);
            alert('若重複嘗試無法登入, 請改用其他帳號登入-2')
            // window.location.reload();
        },
    })

    const [getNonce, { loading: loadingNonce }] = useLazyQuery(GET_LOGIN_NONCE, {
        onCompleted: data => {
            if (data && data.nonce) {
                MMLoginConfirm(data.nonce);
            } else {
                alert('LOGIN ERROR!!')
                window.location.reload();
            }
        },
        onError: (err: any) => {
            console.warn(err.message);
            alert('若重複嘗試無法登入, 請改用其他帳號登入-1')
            setMMLogin(false);
        },
    })

    const MMLoginClick = async () => {
        if (loadingNonce)
            return;

        setMMLogin(true);
        // Check if MetaMask is installed
        if (!(window as any).ethereum) {
            window.alert('Please install MetaMask first.');
            setMMLogin(false);
            return;
        }

        if (!window.web3api || !window.web3api.eth)
            if (await InitialWeb3() !== 1)
                window.alert('Please confirm your Metamask plugin is login and ready.');

        if (!window.web3api || !window.web3api.eth) {//fail retrieve
            setMMLogin(false);
            return;
        }

        const account = await window.web3api.eth.getAccounts();
        if (account && account[0]) {
            let walletAddress = account[0].toLowerCase();
            setWallet(walletAddress);
            getNonce({ variables: { walletAddress } });
        } else {
            window.alert('Please activate MetaMask first.');
            setMMLogin(false);
            return;
        }
    };

    async function MMLoginConfirm(nonce: string) {
        if (!window.web3api || !window.web3api.eth) //retrieve web3 if undefined
            if (await InitialWeb3() !== 1)
                window.alert('Please confirm your Metamask plugin is login and ready.');

        if (!window.web3api || !window.web3api.eth) {//fail retrieve
            setMMLogin(false);
            return;
        }

        const signIn = await CallMMSign({ wallet, nonce });
        if (signIn?.wallet && signIn?.signature) {
            thirdPartyLogin({ variables: { email: signIn?.wallet, id: signIn?.wallet, source: 'Metamask', displayName: signIn?.signature, avatar: 'NA' } });

        } else {
            alert('Invalid info, you need to sign the message to be able to log in.');
            window.location.reload();
        }
    }

    const CallMMSign = async ({ wallet, nonce, }: { wallet: string; nonce: string; }) => {
        try {
            const signature = await window.personal!.sign(
                prefix + nonce,
                wallet,
                '' // MetaMask will ignore the password argument here
            );
            return { wallet, signature };
        } catch (err) {
            console.warn(err);
            setMMLogin(false);
            alert('You need to sign the message to be able to log in.');
        }
    };

    return (
        <div className={'flex cursor-pointer ' + (className ? className : '')} onClick={MMLoginClick}>
            {MMLogin ? <div className='w-full flex'> <div className='m-auto'><Spinner /></div></div> :
                <div className='w-full flex '>
                    {hideIcon ? '' : <IMG className='w-6 h-6 mr-2 my-auto' src='MM' />}
                    <p className={labelClass ? labelClass : ' my-auto NotoSans font-medium w-48'} >
                        {label ? label : 'Login with Metamask'}</p>
                </div>}
        </div>
    )
}

export const GoogleLoginButton = ({
    children,
    url,
    forward,
    customeStyle,
    ...props
}: UserAuthenticatorProps) => {
    const [thirdPartyLogin] = useLazyQuery(THIRD_PARTY_LOGIN, {
        onCompleted: data => {
            if (data) {
                if (
                    data.user !== null &&
                    data.user.accessToken !== null
                ) {
                    const cookies = new Cookies();
                    cookies.set(SITE_COOKIE, data.user, {
                        path: '/',
                        maxAge: data.user.tokenLife,
                    });
                    let nextUrl;
                    if (forward) nextUrl = forward;
                    const { n } = parseQuerystring(window.location.search);
                    if (typeof n === 'string') nextUrl = n;
                    if (nextUrl) {
                        window.location.href = nextUrl;
                    } else {
                        window.location.reload();
                    }
                } else {
                    let nextUrl;
                    if (forward) nextUrl = forward;
                    const { n } = parseQuerystring(window.location.search);
                    if (typeof n === 'string') nextUrl = n;
                    if (nextUrl) {
                        window.location.href = nextUrl;
                    } else {
                        window.location.reload();
                    }
                }
            }
        },
        onError: (err: any) => {
            error(err.message);
            alert('若重複嘗試無法登入, 請改用其他帳號登入-3')
            window.location.reload();
        },
    })

    const onGoogleSuccess = useGoogleLogin({
        onSuccess: tokenResponse =>
            thirdPartyLogin({ variables: { email: 'GHK_GOOGLE_AUTH_TOKEN', id: tokenResponse.access_token, source: 'Google', displayName: '', avatar: 'NA' } }),
        onError: () => { alert('若重複嘗試無法登入, 請改用其他帳號登入-4')}
    });

    const authState = props.authState;
    const inAppBrowser = IsInAppBrowser();

    //檢查是否登入，已經登入就直接render
    if (UserAuth.isAuthenticate()) {
        return <EnsureLoggedIn authState='signedIn'>{children}</EnsureLoggedIn>;
    } else if (url && url.indexOf('fund') !== -1) {
        let nextUrl = window.origin + '/login/?n=' + encodeURIComponent(url);
        window.location.href = nextUrl;
    }

    return (
        <>
            {inAppBrowser ? '' :
                (customeStyle && customeStyle === 'Short' ?
                    <Button onClick={onGoogleSuccess}
                        className=' text-white my-auto px-3 h-[21px] bg-[#fb7f65] rounded-[31px] flex justify-center'>
                        <p className='text-sm font-semibold my-auto'>登入</p>
                    </Button> :
                <Button onClick={onGoogleSuccess}
                    style={{ boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.15)', minWidth: 260 }}
                    className=' px-7 mx-auto h-12 mt-3 flex bg-white border border-[#b6b6b6] rounded-[32px]'>
                    <IMG className='my-auto mr-3 h-6 w-6 h-6' src='Google' />
                        <p className={(customeStyle && customeStyle === 'en' ? 'text-lg' : 'text-[21px]') + ' Roboto font-semibold my-auto text-[#767676] truncate'}>
                            {__('Login_With_Google')}</p>
                    </Button>)}
            <EnsureLoggedIn authState={authState}>{children}</EnsureLoggedIn>
        </>
    );
};

export const GoogleLogin = ({ forwardUrl, customeStyle }: { forwardUrl?: string, customeStyle?: string }) => {
    let params = window.location.href.split('n=');
    if (!forwardUrl)
        forwardUrl = params.length > 1 ? params[1] : '';
    return (
        <GoogleLoginButton customeStyle={customeStyle} authState='signIn' forward={forwardUrl ? forwardUrl : undefined} />
    );
};

export const AWSLoginButton = ({
    children,
    url,
    forward,
    customeStyle,
    ...props
}: UserAuthenticatorProps) => {
    const [loginStat, setLoginStat] = useState(false)
    const [thirdPartyLogin] = useLazyQuery(THIRD_PARTY_LOGIN, {
        onCompleted: data => {
            if (data) {
                if (
                    data.user !== null &&
                    data.user.accessToken !== null
                ) {
                    setLoginStat(true)
                    const cookies = new Cookies();
                    cookies.set(SITE_COOKIE, data.user, {
                        path: '/',
                        maxAge: data.user.tokenLife,
                    });                   
                } 
                const awsRedirect = localStorage.getItem('awsRedirect');
                localStorage.removeItem('awsRedirect');
                if(awsRedirect){
                    window.location.href = awsRedirect
                }else window.location.href = window.location.origin
            }
        },
        onError: (err: any) => {
            error(err.message);
            alert('若重複嘗試無法登入, 請改用其他帳號登入-3')
            window.location.reload();
        },
    })

    const authState = props.authState;
    const inAppBrowser = IsInAppBrowser();

    const getUser = useCallback(async (): Promise<void> => {
        try {
            const user = await getCurrentUser()
            const session = await fetchAuthSession(); // <-- This line throws an error
            let token = session.tokens?.idToken ? session.tokens?.idToken.toString() : 'NA'
            if (user && !loginStat)
                thirdPartyLogin({ variables: { email: 'GHK_AWS_AUTH_TOKEN', id: token, source: 'AWS', displayName: 'AWS_User', avatar: 'NA' } })

        } catch (error) {
            console.error('Error fetching current user:', error)
            // alert('Not signed in')
        }
    }, [loginStat, thirdPartyLogin])

    useEffect(() => {
        // if(!loginStat){
            const unsubscribe = Hub.listen('auth', ({ payload }) => {
                switch (payload.event) {
                    case 'signInWithRedirect':
                        getUser()
                        break
                    case 'signInWithRedirect_failure':
                        alert('An error has occurred during the OAuth flow.')
                        console.error('OAuth flow error:', payload.data)
                        break
                    default:
                        console.warn(`Unhandled auth event: ${payload.event}`)
                        break
                }
            })
            getUser()
            return () => unsubscribe()
        // }        
    }, [getUser])



    //檢查是否登入，已經登入就直接render
    if (UserAuth.isAuthenticate()) {
        return <EnsureLoggedIn authState='signedIn'>{children}</EnsureLoggedIn>;
    } else if (url && url.indexOf('fund') !== -1) {
        let nextUrl = window.origin + '/login/?n=' + encodeURIComponent(url);
        window.location.href = nextUrl;
    }

    return (
        <>
            {inAppBrowser ? '' :
                (customeStyle ? customeStyle === 'Short' ?
                    <Button onClick={signInWithRedirect}
                        className=' text-white my-auto px-3 h-[21px] bg-[#fb7f65] rounded-[31px] flex justify-center'>
                        <p className='text-sm font-semibold my-auto'>{__('Login')}</p>
                    </Button> :
                     <Button onClick={signInWithRedirect}
                     style={{ boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.15)', minWidth: 260, backgroundColor: customeStyle === 'APP' ? '#B6B6B6':'black', borderColor: '#D0D0D0' }}
                     className=' px-7 mx-auto h-12 my-4 text-center border rounded-[32px] opacity-50'>
                         <p className={`text-lg Roboto font-medium my-auto ${customeStyle ? 'text-white': 'text-[#D0D0D0]'} truncate`}>{__('Other_Login')}</p>
                     </Button>:
                    <div className="flex">
                <Button onClick={signInWithRedirect}
                    style={{ boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.15)', minWidth: 260, backgroundColor: 'black', borderColor: '#D0D0D0' }}
                    className=' px-7 mx-auto h-12 my-4 text-center border rounded-[32px] opacity-50'>
                        <p className={'text-lg Roboto font-medium my-auto text-[#D0D0D0] truncate'}>{__('Other_Login')}</p>
                    </Button>
                    {/* <Button className="text-white" onClick={() => {signOut();}}>Sign Out</Button> */}
                    </div>)}
            <EnsureLoggedIn authState={authState}>{children}</EnsureLoggedIn>
        </>
    );
};

export const AWSLogin = ({ forwardUrl, customeStyle }: { forwardUrl?: string, customeStyle?: string }) => {   
    let params = window.location.href.split('n=');
    // if (!forwardUrl)
    //     forwardUrl = params.length > 1 ? params[1] : '';

    if(forwardUrl){
        localStorage.setItem('awsRedirect', forwardUrl);   
    }
    else if(params.length > 1){
        localStorage.setItem('awsRedirect', window.location.href);   
    }
    
    return (
        <AWSLoginButton customeStyle={customeStyle} authState='signIn' forward={forwardUrl ? forwardUrl : undefined} />
    );
};

export const LogInPage = () => {
    let params = window.location.href.split('n=');
    let forwardUrl = params.length > 1 ? params[1] : '';
    return (
        <UserAuthenticator authState='signIn' forward={forwardUrl ? forwardUrl : undefined}>
            <RedirectAfterLogin />
        </UserAuthenticator>
    );
};