import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import gql from 'graphql-tag';
import qs from 'qs';
import Slide from '@mui/material/Slide';

import { Button, Spinner } from '../lib/components';
import { withAuthState } from '../lib/auth';
import { IMG, SVG } from '../lib/imgLib';
import __, { setLanguage, getLanguage } from '../lib/gettext';
import UserAuth from '../lib/userAuth';
import './css/header.css';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { usePWAInstall } from 'react-use-pwa-install';
import { isIOS, isMobile } from 'react-device-detect';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        border: '1px solid #fff',
        padding: '10px 26px 10px 12px',
        '&:focus': {
            borderRadius: 4,
            borderColor: '#fff',
        },
    },
}));

const LanguageSelector = () => {
    const [currentLang, setLang] = useState(getLanguage());

    const handleChange = (event: SelectChangeEvent) => {
        let language = event.target.value as string;
        if (language === currentLang)
            return;
        setLanguage(language);
        setLang(event.target.value as string);
        window.location.reload();
    };

    return (
        <div className='relative my-auto text-center w-24 h-12 p-0 ' >
            <Select className='h-10 text-sm mt-1 foucus:outline-none'
                sx={{ border: '0px solid #000', }}
                value={currentLang}
                label='lang'
                onChange={handleChange}
                input={<BootstrapInput />}>
                <MenuItem value={'zh'}>繁</MenuItem>
                <MenuItem value={'en'}>EN</MenuItem>
            </Select>
        </div >
    );
};

const Header = () => {
    const [showMenu, setMenu] = useState(false);
    const [auth, setAuth] = useState(false);
    const [corp, setCorp] = useState<{ id: number, corpName: string }[]>([])
    const pwaInstall = usePWAInstall();
    const [showPWA, setPWA] = useState(false);
    const [showIosPWA, setIosPWA] = useState(false);
    const [user, setUser] = useState();

    const GET_HEADER_PROFLE = gql`
        query GetHeaderProfile {
            me {
                id
                avatarUrl
                email
                displayName
            }
            auth:getFGUserAuth
            corp:getUserCoprsList
        }`;
    const { loading } = useQuery(GET_HEADER_PROFLE, {
        fetchPolicy: 'network-only',
        onCompleted: ((data: any) => {
            if (data.corp) {
                try {
                    if (data && data.me && data.me.id > 0) setUser(data.me);
                    let list = data.corp.map((info: any) => {
                        return JSON.parse(info)
                    });
                    setCorp(list);
                } catch (err) {
                    console.warn(err);
                }
            }
            if (data.auth && data.auth.length > 0)
                setAuth(true);
        })
    });
    const url = useLocation().pathname;


    const logOut = () => {
        UserAuth.signOut();
        window.location.reload();
    };


    const HEADER_BLACKLIST = ['/apps/']
    let hideHeader = false;
    HEADER_BLACKLIST.forEach((blackList) => {
        blackList = blackList.toLowerCase();
        if (url.toLowerCase().includes(blackList)) {
            hideHeader = true;
        }
    });

    if (hideHeader) return null;

    async function HandlePwaInstall() {
        setPWA(false);
        if (pwaInstall !== null) {
            await pwaInstall();
        }
    }

    function ShowPWA() {
        let lastShow = localStorage.getItem('FANSI-GO-PWA');
        if (!lastShow) lastShow = '0';
        let curr = new Date().getTime();
        let standAlone = window.matchMedia('(display-mode: standalone)').matches;
        // if (curr - parseInt(lastShow + '') > 86400 * 1000) { // show pwa popup once a day
            if (isIOS && isMobile && !standAlone)
                setIosPWA(true);
            setPWA(true)
            localStorage.setItem('FANSI-GO-PWA', curr + '');
        // }
    }

    const toggleMenuEventListner = () => {
        setMenu(false);
        document.body.removeEventListener('click', toggleMenuEventListner);
    };

    const toggleHidden = (e: MouseEvent) => {
        e.stopPropagation();
        if (showMenu) {
            setMenu(false);
        } else {
            setMenu(true);
        }
        if (showMenu) {
            document.body.removeEventListener('click', toggleMenuEventListner);
        } else {
            document.body.addEventListener('click', toggleMenuEventListner);
        }
    };

    return (
        <div className={'fixed left-0 top-0 w-full flex text-black z-90 bg-white flex'} style={{ boxShadow: '0 1px 7px 0 rgba(0, 0, 0, 0.25)' }}>
            <div className='relative flex section-app lg:max-w-pc h-[50px] px-4'>
                <div className='w-1/2 flex'>
                    <Link to='/' className='my-auto'>
                        <IMG src='FANSIGO' className='w-32 ' />
                    </Link>
                    <Link to='/collection/tickets' className='ml-10 my-auto hidden lg:block'>
                        <div className='flex'>
                            <IMG src='TicketH' className='w-5 h-5' style={{color: '#484848'}}/>
                            <p className='ml-1 text-center text-sm text-gray-48'>{__('FGTR_My_Ticket')}</p>
                        </div>                        
                    </Link>
                    <Link to='/collection' className='ml-10 my-auto hidden lg:block'>
                        <div className='flex'>
                            <IMG src='MemberG' className='w-5 h-5' style={{color: '#484848'}}/>
                            <p className='ml-1 text-center text-sm text-gray-48'>{__('FOOTER_Collection')}</p>
                        </div>    
                    </Link>
                </div>
                <div className='w-1/2 flex justify-end'>
                        <LanguageSelector />
                    {loading ? <Spinner name='circle' className='my-auto' /> :
                        (user ? <>
                        <SVG onClick={toggleHidden} src='menu' className='cursor-pointer my-auto' sx={{ width: 30, height: 30 }} />
                        {showMenu ?
                            <div className='absolute w-44 min-h-20 pr-4 right-0 mt-11 overflow-hidden'>
                                <Slide direction='down' in={showMenu} mountOnEnter unmountOnExit>
                                    <div className='relative bg-black bg-white p-3 rounded-[8px] border border-[#e7e7e7]' style={{ zIndex: 99, boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.14)' }}>
                                        <div className='block lg:hidden'>                                            
                                            <div onClick={ShowPWA} className='w-full mb-2 flex justify-center'>
                                                <p className='text-center text-sm my-auto'>{__('FOOTER_PWA_Create_ShortCut')}</p>
                                                <div className='rounded-md ml-1 my-auto p-1 bg-[#00b9c4] text-white text-xs'>{__('Recommand')}</div>
                                            </div> 
                                            <div className='w-full h-px my-2 bg-[#efefef]' />
                                        </div>
                                        <Link to='https://sweet-angle-e85.notion.site/FAQ-45d76bebcf1c4c02888dbc457ab98b3d' target='_blank' className='w-full mb-2'>
                                            <p className='text-center text-sm'>{__('WEB_QA')}</p>
                                        </Link>                                        
                                        
                                        {/* <p className={'mt-[2px] w-full text-center Roboto text-xs font-medium truncate ' + (currMenu === 1 ? 'text-quest-red' : 'text-[#9d9d9d]')}>{__('FOOTER_PWA_Create_ShortCut')}</p> */}                                        
                                        {/* <Link to='/collection' className='hidden lg:block w-full mb-2'>
                                            <p className='text-center text-sm'>{__('FOOTER_Collection')}</p>
                                        </Link>
                                        <Link to='/UserProfile' className='w-full'>
                                            <p className='text-center text-sm'>{__('FGU_Edit_Profile')}</p>
                                        </Link> */}
                                        <div className='w-full h-px my-2 bg-[#efefef]' />
                                        <Link to='/collection/tickets' className='w-full mb-2'>
                                            <p className='text-center text-sm'>{__('FGTR_My_Ticket')}</p>
                                        </Link>

                                        {auth ? <>
                                            <div className='w-full h-px my-2 bg-[#efefef]' />
                                            <Link to='/dashboard' className='w-full'>
                                                <p className='text-center text-sm'>工作人員後台</p>
                                            </Link> </> : ''}

                                        {corp ?
                                            corp.map((info: any) => {
                                                return (
                                                    <>
                                                        <div className='w-full h-px my-2 bg-[#efefef]' />
                                                        <Link to={'/corporation/' + info.id} className='w-full'>
                                                            <p className='text-center text-sm'>{__('HEADER_MY_CORP')}</p>
                                                            {corp.length > 1 ?
                                                                <p className='text-center text-sm'>{info.corpName}</p> : ''}

                                                        </Link>
                                                    </>
                                                )

                                            })
                                            : ''}


                                        <div className='w-full h-px my-2 bg-[#efefef]' />
                                        <button className='relative z-50 w-full cursor-pointer ' onClick={logOut} >
                                            <p className='w-full text-center text-sm'>{__('Logout')}</p>
                                        </button>
                                    </div>
                                </Slide>
                            </div> : ''}
                        </>
                            : <>
                            <a href={'/login?' + qs.stringify({ n: window.location.pathname })} className='my-auto'>
                                <Button className=' text-white my-auto px-3 h-[21px] bg-[#fb7f65] rounded-[31px] flex justify-center'>
                                    <p className='text-sm font-semibold my-auto'>{__('Login')}</p>
                                </Button>
                            </a>
                            </>)}
                        {pwaInstall !== null ?
                        (showPWA && !isIOS ?
                            <Slide direction='down' in={showPWA} mountOnEnter unmountOnExit>
                                {/* <div className='absolute w-full h-28 -top-[112px] px-4'> */}
                                <div className='absolute w-full h-28 top-[60px] left-0 px-4'>
                                    <div className='w-full h-24 bg-black text-white rounded-xl p-4 flex'>
                                        <IMG src='FANSIGO_S' className='h-full aspect-square mr-3' />
                                        <div className='w-full'>
                                            <p className='font-medium text-sm'>{__('FOOTER_PWA_INSTALL')}</p>
                                            <div className='w-full flex justify-end'>
                                                <button className='px-2 h-7 z-90 mr-6' onClick={() => { setPWA(false) }}>
                                                    <p className='w-full text-center text-xs my-auto'>{__('Not_Now')}</p></button>
                                                <button className=' rounded-3xl w-24 h-7 bg-[#ffc76e] z-90' onClick={HandlePwaInstall}>
                                                    <p className='w-full text-center text-gray-48 font-medium my-auto'>{__('FOOTER_PWA_Create_ShortCut')}</p></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                            : '') : ''}
                    {showIosPWA ? <Slide direction='down' in={showIosPWA} mountOnEnter unmountOnExit>
                        {/* <div className='absolute w-full px-8 -top-[340px]'> */}
                        <div className='absolute w-full px-8 top-[60px] left-0'>
                            <div className='w-full bg-black text-white rounded-xl p-6'>
                                <div className='w-full'>
                                    <div className='w-full flex justify-center'>
                                        <IMG className='w-16 h-16 mr-2' src='Safari' />
                                        <IMG className='w-6 my-auto mr-2' src='ArrowRight2' />
                                        <IMG className='w-16 h-16 mr-2' src='FANSIGO_S' />
                                    </div>
                                    <p className='mt-2 text-center font-medium'>{__('FOOTER_PWA_IOS')}</p>
                                    <img className='w-full mt-2' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Events/NeonOasis/ios-PWA.png' alt='pwa' />
                                    <div className='mt-2 w-full flex justify-center font-medium'>
                                        <p>{__('FOOTER_PWA_IOS_INFO1')}</p>
                                        <IMG className='w-4 my-auto mx-1' src='ShareOrange' />
                                        <p>{__('FOOTER_PWA_IOS_INFO2')}</p>
                                    </div>
                                </div>
                                <div className='w-full flex justify-center mt-6'>
                                    <button className='px-2 h-7' onClick={() => { setIosPWA(false) }}>
                                        <p className='w-full text-center text-xs my-auto underline'>{__('Not_Now')}</p></button>
                                </div>
                            </div>
                        </div>
                    </Slide> : ''}
                </div>
            </div>
        </div>
    );
};


export default withAuthState(Header);