export const zh = {
    DC_INVITE_LINK: 'https://discord.gg/vtkV88bN2E',
    FANSI_IG: 'https://www.instagram.com/fansi_nft',
    FANSI_Twitter: 'https://twitter.com/metaboom_nft',
    FANSI_LinkTree: 'https://linktr.ee/fansi_nft',
    FANSI_OpenSea: 'https://opensea.io/collection/metaboom-nft',
    MetaBoom_URL: 'https://metaboom.fansi.me',
    RisingStars_URL: 'https://metaboom.fansi.me/hatching',
    APP_Google: 'https://play.google.com/store/apps/details?id=me.fansi.app',
    APP_Apple: 'https://apps.apple.com/tw/app/metaboom/id6444411102',
    About: '關於',
    Lang_Zh: '繁中',
    Lang_En: 'EN',
    Google_Login: 'Google Login',
    Facebook_Login: 'Facebook Login',
    Login_N_Agree: '登入即代表您已詳閱並同意',
    Terms_of_Service: '服務條款',
    Privacy_Policy: '隱私政策',
    WEB_QA: '常見問題',
    Home_Desc: 'FANSI GO: Web3票務新體驗，現場表演更珍貴',
    Web_Default_Desc: '數位票根隨時紀錄不遺失，贏取更多表演福利回饋。',

  //Special
  FG_FANSILAND_INFO: '您將在三至五個工作天，\n收到票券兌換系統信',

  //票劵頁面 FANSI GO Tickets
  FGT_Select_Show: '請選擇活動場次進行購票',
  FGT_Show_List: '活動場次',
  FGT_Event_Info: '活動簡介',
  FGT_Pick_Section: '選擇票券種類',
  FGT_Sale_End: '結束販售',
  FGT_Sale_Start_At: '開始販售',
  FGT_Not_Sale_Yet: '尚未開賣',
  FGT_Add_Calendar: '+ 行事曆',
  FGT_Section_Usage: '票券可使用時間',
  FGT_Section_Info: '票券說明',
  FGT_Ticket_Purchased: '你已經購買 {0} 張，',
  FGT_Ticket_Available: '本場次還可以購買 {0} 張。',
  FGT_Show_Purchased_Max: '本場次購買額度已達上限。',
  FGT_Purchased_Max: '已達購買上限',
  FGT_Whitelist_Warning: '您不在優惠名單',
  FGT_Sale_Start: '本活動開始販售時間為',
  FGT_Back_To_Event_Page: '回到訂購頁面',
  FGT_Purchase_Now: '立即購買',
  FGT_Uncomplete_Order: '你有一筆未完成的訂單，要繼續嗎？',
  FGT_Purchase_Limit_Info: '單一場次能購買上限為 {0} 張',
  FGT_SEC_Tickets_Remaion: '（ 剩{0}張 ）',
  FGT_Select_Seat: '選擇座位',
  FGT_Reset_Seat: '重選所有座位',
  FGT_No_Seat: '尚未選位',
  FGT_Select_Seat_Result: '選位結果',
  FGT_Select_No: '購票數量',
  FGT_Seat_Section: '您的座位區域為',
  FGT_Seat_Info: '圖示說明',
  FGT_Seat_Empty:'空位',
  FGT_Seat_Selected:'你選的',
  FGT_Seat_Sold: '已售出',
  FGT_Seat_Confirm: '確認座位',
  FGT_Seat_Cancel: '取消座位',
  FGT_Seat_Current: '你目前選擇的是',
  FGT_Seat_Cancel_Current: '你確定要取消',
  FGT_Remain: '保留',
  FGT_Seat_Reset: '重選所有座位',
  FGT_Seat_Checkout: '點擊下一步，代表你已確認座位資訊。',
  FGT_REFUND_TITLE: '退票需知',
  FGT_REFUND_DETAIL: '詳細退票說明',
  FGT_CHECK_REFUND_DETAIL: '請看詳細退票方法',
  FGT_REFUND_RULE_1: '依文化部公告修定之『',
  FGT_REFUND_RULE_2: '藝文表演票券定型化契約應記載及不得記載事項',
  FGT_REFUND_RULE_3: '』第六條「退、換票機制」之規定，本節目採用',
  FGT_REFUND_RULE_4: '方案二',
  FGT_REFUND_RULE_5: '：購票後(不含購票當日)三日內可退票，第四日起即不接受退票申請，退票手續費上限為票面價格的 5%。',
  FGT_REFUND_RULE_6: '例：2025/01/01 購票，退票截止日為 2025/01/04，2025/01/05 (含)起不再受理退票，後續退票，以此類推。\n\n若購票日距活動日不足三日，請於表定表演日前一日完成退票操作。\n例：表演時間為 2025/01/31 20:00-22:00，若於 2025/01/28 後購買，請於 2025/01/30 23:59 前完成退票。', 
  'FGRT-ERR-2': '票劵預留失敗，請重新嘗試',
  'FGRT-ERR-3': '超過購買上限',
  'FGRT-ERR-3-NOTE': '超出您在本活動的購票上限',
  'FGRT-ERR-4': '剩餘票劵不足',
  'FGRT-ERR-5': '訂單資訊錯誤，請重新嘗試',
  'FGRT-ERR-6': '尚有未付款的訂單，請先',
  'FGRT-ERR-6-1': '完成付款',
  'FGT-ReserveSeat-11': '選位錯誤',
  'FGT-ReserveSeat-12': '選位錯誤',
  'FGT-ReserveSeat-13': '位置已被選取',
  'FGT-ReserveSeat-14': '選位錯誤',

  //payment page
  'FGRD-ERR-11': '錯誤的序號，請重新輸入',
  'FGRD-ERR-12': '序號已被其他訂單使用',
  'FGRD-ERR-13': '序號不可用在此場次',
  'FGRD-ERR-14': '序號過期',
  'FGRD-ERR-15': '超過使用次數',
  'FGRD-ERR-16': '優惠碼被搶光了',
  'FGRD-ERR-21': '發生錯誤，請重新嘗試。',
  FGPP_Handling: '，手續費',
  FGPP_Line_Pay: '行動支付',
  FGPP_Aftee: '先享後付',
  FGPP_Aftee_Pay: '超商/ATM/轉帳',
  FGPP_Paypal_Pay: '境外支付',
  FGPP_Cash: '現場支付',
  FGPP_Cash_Pay: '現金支付',
  FGPP_CreditCard: '信用卡付款',
  FGPP_CreditCard_Pay: '信用卡支付',
  FGPP_Crypto_Pay: '加密貨幣支付',
  FGPP_Payment_Completed: '已完成付帳',
  FGPP_Ticket: '付款方式',
  FGPP_Free: '免費票',
  FGPP_Payment_Method: '付款方式',
  FGPP_Payment_Warning_1: '您的訂單已保留，請在 ',
  FGPP_Payment_Warning_2: ' 分鐘內完成訂單。\n若逾期未完成，系統將取消您的訂單',
  FGPP_Checkout_Warning: '已結帳後無法變更',
  FGPP_Billing_Detail: '金額明細',
  FGPP_Third_Party_Handling: '服務費',
  FGPP_Third_Party_Handling_Note: '服務費包含票務處理費及金流手續費',
  FGPP_Notice: '注意事項',
  FGPP_Note_1: '請確實核對訂購內容與價格，本系統未提供換票服務。',
  FGPP_Note_2: '如您購買了複數張票，請使用分票功能，個別驗票入場。',
  FGPP_Note_3: '本系統採QR Code電子票券形式，系統將於開放進場前24小時展示QR Code。',
  FGPP_Note_4: '活動退票期限：',
  FGPP_Note_4_1: '購票後 3 日內。',
  FGPP_Note_4_2: '如購票時距離表演不足 3 天，則退票截止時間為表演日前一天 23:59。',
  FGPP_Note_12: '加購商品退票：活動前一日23:59，詳細規則請詳參加購活動說明，依主辦單位規定辦理。',
  FGPP_Note_13: '揪團功能主動解散或退出將收取4％退票手續費，被動流局則全額退款。',
  FGPP_Note_5: '退款方式：',
  FGPP_Note_6: 'Line pay：收取4％退票手續費，當下退刷完成。',
  FGPP_Note_7: 'AFTEE：全額退款，若尚未完成繳費，當下退款完成；若已完成繳費，約需14個工作天。',
  FGPP_Note_8: 'Paypal：票價全額退款，不包含8%手續費。',
  FGPP_Note_9: 'USDT(Polygon)：收取4％退票手續費，約需14個工作天。',
  FGPP_Note_10: '現金支付：不可退票。',
  FGPP_Note_11: '信用卡: 收取4％退票手續費，當下退刷完成。',
  FGPP_Confirm_Order_Info: '確認訂單內容',
  FGPP_Total_Amount: '總金額',
  FGPP_Next_Step_Warning: '點擊下一步，代表你已詳閱並同意',
  FGPP_Pre_Step: '上一步',
  FGPP_Enter_Checkout: '進行結帳',
  FGPP_Payment_Refund_Warning: '請留意退票時效且退款金額可能不包含手續費',
  FGPP_QRcode_Warning: '請出示 QR-Code 給相關工作人員掃描',
  FGPP_Cash_Confirm: '檢查交易結果',
  FGPP_Cash_Confirm_Warning: '請在交易完成後，點擊“檢查交易結果”',  
  FGPP_Payment_Uncomplete: '尚未完成付款',
  FGPP_Payment_Method_Error: '結帳方式錯誤',
  FGPR_Aftee_Warning: '此筆訂單已成立，若逾期未繳費，此訂單並不會自動取消。請於AFTEE規定時限內繳款，以避免產生滯納金。',
  FGPP_Order_Expired: '訂單已逾期，請重新訂票',
  FGPP_Start_Checkout: '開始結帳',
  FGPP_NextStep_Warning: '開始結帳後，將無法變更\n付款方式及優惠碼',
  FGPP_SEAT_INFO: '座位資訊',
  FGPP_SEAT: '座位',
  FGPP_Aftee_Warning_1: 'AFTEE將代您支付費用。您需向AFTEE完成付款，否則將產生滯納金。',
  FGPP_Aftee_Warning_2_1: '未付款不會自動取消訂單',
  FGPP_Aftee_Warning_2_2: '。請務必至AFTEE查看並繳清款項。',
  FGPP_Aftee_Warning_3: '如需退票，請於退票時效內在FANSI GO票券頁操作。',
  FGPP_User_Login_Warning: '請登入原本訂票的帳號付款',
  FGPP_Change_Account: '切換帳號',
  FGPP_Wrong_Account: '帳號不符',
  FGGP_End_Processing: '票券整理中',

  //merchandise 
  FGM_PURCHASE_DETAIL: '活動詳情',
  FGM_PURCHASE_EVENT_DETAIL: '加購活動詳情',
  FGM_PlEASE_SELECT_SHOW: '請先選擇加購場次',
  FGM_PLEASE_SELECT_ITEM: '請選擇商品規格',
  FGM_ITEM_LIST: '商品列表',
  FGM_ALL_ITEM: '全部商品',
  FGM_ITEM_DETAIL: '商品資訊',
  FGM_QUALIFY: '資格限定',
  FGM_ADD_TO_CART: '加到購物車',
  FGM_CHECK_CART: '檢視購物車',
  FGM_SHOPPINGCART: '購物車',
  FGM_SHOPPINGCART_HINT: '購物車僅限一個活動，加入其他活動請先',
  FGM_SHOPPINGCART_CLEAR: '清空購物車',
  FGM_SHOPPINGCART_CLEAR_TITLE: '清空購物車內容',
  FGM_SHOPPINGCART_CLEAR_CONTENT: '你確定要清空購物車內容？',
  FGM_CONTINE_SHOP: '繼續購物',
  FGM_ENTER_CHECKOUT: '前往結帳',
  FGM_TOTAL: '總計', 
  FGM_UNIT: '項',
  FGM_PURCHASE_ITEM: '加購商品',
  FGM_NO_MERCH: '目前尚未有商品',
  FGM_SELECTED_AMOUNT: '選擇兌換數量',
  FGM_CLEAR: '清除',
  FGM_SCAN_EXCHANGE: '掃碼兌換',
  FGM_REDEEMED: '已取貨',
  FGM_EXCHANGED_AMOUNT: '兌換數量',
  FGM_VAR_ID: '編號',
  FGM_EXCHANGE_TIME: '兌換時間',
  FGM_EXCHANGE_NOTICE: '請留意兌換數量是否正確',
  FGM_RETURN_MERCH: '關閉兌換清單',
  FGM_CANCEL_REDEEM: '取消清單',

  //payment result
  FGPR_Payment_Complete: '恭喜訂單已成立',
  FGPR_Payment_Complete_Semi: '實際狀況以收到票券為主',
  FGPR_Crypto_Warning: '請留意實際完成鏈上驗證，方完成支付。',
  FGPR_Ticket_Confirm: '到”票券”確認',
  FGPR_Trans_Fail: '交易失敗\n沒關係，再重新一次',

  //票夾頁面
  FGC_Ticket: '票券',
  FGC_Ticket_No: '票號',
  FGC_Original_Price: '原價',
  FGC_Ticket_Not_Own: '必須收回所有的票劵才能進行退票',
  FGC_Refund: '進行退票',
  FGC_Confirm_Refund: '確認退票',
  FGC_Refund_Fail: '退票失敗',
  FGC_Not_Refundable: '超過退款日期',
  FGC_Not_Aavailable: '尚未開放',
  FGC_Event_Ended: '已結束',
  FGC_Redeemed: '已兌換',
  FGC_Not_Redeem: '未兌換',
  FGC_Barcode_Notice1: '請避免使用深色模式，保持白色背景，調高螢幕亮度',
  FGC_Barcode_Notice2: '將手機螢幕亮度調高，掃描起來更順暢',
  FGC_Barcode_Notice3: '入場票券 QR-CODE\n將於開演前24小時開放',
  FGC_Barcode_Notice4: '物販票券 QR-CODE\n將於活動前24小時開放',
  FGC_Owner: '聯絡人',
  FGC_My_Transactions: '我的訂單記錄',
  FGC_Zero_Ticket_Notice1: '你還沒有任何票券',
  FGC_Zero_Ticket_Notice2: '購買成功的票券都會在這邊顯示',
  FGC_Ticket_Tax_No: '行政票號',
  FGC_Ticket_Tax_No_Warning: '本娛樂票券經台北市稅捐稽徵處１１３年０４月０３日大安乙字第１１３５３０２３９７號核准使用，並已向演出之台北市稅捐稽徵機關申請報繳娛樂稅，內含代徵娛樂稅',
  FGC_Ticket_Tax_Free: '本娛樂票券經台北市稅捐稽徵處１１３年０４月０３日大安乙字第１１３５３０２３９７號核准使用，並已向演出之台北市稅捐稽徵機關申請報繳娛樂稅，不含代徵娛樂稅',

  FGC_TAB_TICKET: '活動票卷',
  FGC_TAB_MERCH: '商品票卷',
  FGC_TAB_USE: '未使用',
  FGC_TAB_USED: '兌換清單',
  FGC_MERCH_HISTORY: '商品訂單紀錄',
  FGC_HOLDING: '持有數量',
  FGC_REMAIN: '剩餘數量',
  FGC_MERCH_DETAIL: '查看商品明細',
  FGM_NO_MERCH_HINT: '請查看兌換清單，確認是否有未兌換商品',

  //分票
  FGC_Share: '我要分票',
  FGC_Generate_Link: '產生領票連結',
  FGC_Generating: '請稍後...',
  FGC_Share_Warning: '請留意分享對象，除非對方退還，否則無法收回/退票。\n 你的票券被領取後，會轉移至分享對象的票匣。',
  FGC_Copied: '已複製',
  FGC_Return_Ticket: '我要退還票券',
  FGC_Return_Ticket_Info: '將票券退還給購買人',
  FGC_Return_Success: '票劵已成功歸還',


  //折扣頁面
  FGD_Discount: '折扣',
  FGD_Discount_Expired: '優惠已逾期',
  FGD_Expire_On: '使用期限',
  FGD_Coupon_Code: '優惠碼',
  FGD_Coupon_Warning: '請注意一組優惠碼只可以折抵一張訂單',
  FGD_Zero_Coupon: '目前還沒有優惠',
  FGD_Zero_Coupon_Info: '持續關注，優惠出現會在第一時間通知你',
  FGD_Coupons: '優惠卷',
  FGD_OnGoing: '可使用',
  FGD_Expired: '已過期',
  FGD_My_Coupons: '我的優惠券',
  FGD_My_Coupon_Info: '折扣優惠輕鬆掌握不錯過',

  //足跡頁面
  FGP_Badge_Info: '徽章資訊',
  FGP_Claimed_Date: '取得日期',
  FGP_Unclaim: '未取得',
  FGP_Event_Holder: '主辦單位',
  FGP_Badge_Collection: '活動徽章圖鑑',
  FGP_Footprints: '活動足跡',
  FGP_Goal_Achieve: '成就達成',
  FGP_My_Footprints: '我的探險足跡',
  FGP_Treasure_Hunt: '現場尋寶活動',
  FGP_No_Footprints: '目前還沒有探險足跡',
  FGP_My_Footprints_Note: '你參與過的活動都會顯示在這裡',

  //個人資訊
  FGU_Edit_Profile: '編輯個人資訊',
  FGU_Profile: '帳戶',
  FGU_Display_Name: '暱稱',
  FGU_Real_Name: '真實姓名',
  FGU_Explorer: '探險家',
  FGU_Footprint: '歷程足跡',
  FGU_Ticket: '票劵',
  FGU_Coupon_List: '優惠清單',
  FGU_Personal_Info: '聯絡人資訊',
  FGU_Name_Warning: '請填真實姓名，儲存後不可修改',
  FGU_Save_Warning: '儲存後不可修改',
  FGU_Gender: '性別',
  FGU_Birthday: '生日',
  FGU_Phone: '電話號碼',
  FGU_ID_Number: '身分證 / 護照號碼',
  FGU_Address: '地址',
  FGU_No_Info: '未填寫',
  FGU_Member_Card: '會員卡',
  FGU_Member_Card_Title: '專屬會員卡',
  FGU_Member_Card_Fill: '完成以下資料\n',
  FGU_Member_General: '專屬會員卡',
  FGU_Member_Unapproved: '尚未開卡',
  FGU_Member_Activate: '啟用會員卡',
  FGU_Member_Date: '開卡日期',
  FGU_Member_Point: '會員集點',
  FGU_Member_Point_Warning: '請再次確認集點金額',
  FGU_Member_Pos_Amount: '消費金額',
  FGU_Member_Neg_Amount: '沖銷金額',
  FGU_Member_Point_Pos: '切換回正數',
  FGU_Member_Point_Neg: '切換成負數',
  FGU_Member_Flip_Back: '詳細',
  FGU_Member_Flip_Front: '返回正面',
  FGU_Upload_Avatar: '從照片上傳',
  FGU_System_Mail: '訂閱最新消息',
  
  //交易頁面
  FGTR_Transaction_Fail: '交易失敗',
  FGTR_Transaction_Completed: '交易完成',
  FGTR_Wait_Confirm: '待確認',
  FGTR_Wait_Payment: '待付款',
  FGTR_Cancel_Order: '交易取消',
  FGTR_Refunded: '已退票',
  FGTR_Show: '場次',
  FGTR_Section: '票種',
  FGTR_Order_Id: '單號',
  FGTR_Amount: '數量',
  FGTR_Order_Date: '訂購時間',
  FGTR_My_Transaction: '我的訂單記錄',
  FGTR_Zero_Order1: '沒有任何訂單紀錄',
  FGTR_Zero_Order2: '交易的結果都會記錄在這邊',
  FGTR_Payment_Method: '請選擇付款方式',
  FGTR_My_Ticket: '我的票劵',
  FGTR_Ticket_Note: '你的所有票券一目了然',

  FGTR_Refund_Date: '退款時間',
  FGTR_Merch_Detail: '查看商品明細',
  FGTR_Confirming_Hint: '款項確認中，請耐心等待...',

  //Event Info
  FGEI_Organizer: '主辦單位',
  FGEI_Ranking: '活動排行',
  FGEI_Ur_Rank: '你的排行',  
  FGEI_Leaderboard: '排行榜',
  FGEI_Leaderboard_Notes: '排行榜之用途以主辦單位公佈的活動規則為主',
  FGEI_Score: '個人積分',
  FGEI_Score_Record: '積分紀錄',
  FGEI_Persional_Score_Record: '個人積分紀錄',
  FGEI_Introduction: '活動任務簡介',
  FGEI_Event_Score :'活動積分',
  FGEI_Event_Score_Fixed: '修正積分',
  FGEI_Score_Notes: '個人積分之用途以主辦單位公佈的活動規則為主',
  FGEI_Badges: '活動徽章',
  FGEI_Badges_Notes: '徽章獲得方式，以活動規則為主',
  FGEI_No_Event: '目前還沒有活動徽章',
  FGEI_No_Event_Notes: '持續關注，有任務出現會在第一時間通知你',
  FGEI_Available: '可領取',
  FGEI_Obtained: '已取得',
  FGEI_Unobtained: '未取得',
  FGEI_Event_Info: '任務資訊',
  FGEI_Share_Now: '立即分享',
  FGEI_Badge_Notes: '徽章實際用途以主辦單位公佈的活動規則為主',
  FGEI_Share_Event: '分享活動',
  FGEI_Share_To: '分享到',
  FGEI_Copy_Link: '複製連結',  
  FGEI_Share_Notes: '地球上的大家！為了讓更多人認識這個表演者！請大家把力量借給他吧！',
  FGEI_Share_Img: '分享紀念票根',
  FGEI_Ticket: '紀念票根',
  FGEI_Download_Ticket_Note: '你可以下載票跟圖片並分享到社群',
  FGEI_Download_Ticket: '下載圖卡',

  //GroupPurchase
  FGGP_ALL_GROUP_PURCHASE: '所有揪團',
  FGGP_WHAT_IS_GROUP_PURCHASE: '什麼是揪團購票？',
  FGGP_TICKET: '張',
  FGGP_TOTAL: '總價',
  FGGP_NUMBER_OF_TICKET: '張數',
  FGGP_COMPLETE: '已成團',
  FGGP_JOIN_NOW: '立即加入',
  FGGP_TICKET_SORTING: '成員已滿',
  FGGP_CREATE_BTN: '我也要發起揪團',
  FGGP_CREATE: '發起揪團',
  FGGP_QA_SUBTITLE_1: '想享受多人票優惠卻找不到夥伴？',
  FGGP_QA_CONTENT_1: '多人票價格優惠，但湊不到足夠的人，還要協調時間、費用，溝通麻煩且有風險。',
  FGGP_QA_SUBTITLE_2: '如何輕鬆揪團購票？',
  FGGP_QA_CONTENT_2: '揪團購票功能協助您媒合成員，每人各自支付，系統自動分發票券，省去溝通成本與取票風險。',
  FGGP_QA_SUBTITLE_3: '還有什麼好處？',
  FGGP_QA_CONTENT_3: '輕鬆享受優惠票價，並支持您喜愛的音樂或活動，讓更多人共同參與。',
  FGGP_QA_CAUTION: '👉🏻 注意事項 👈🏼',
  FGGP_QA_CAUTION_SUBTITLE_1: '發起與退出規則',
  FGGP_QA_CAUTION_CONTENT_1_1_1: '活動開演前7日起',
  FGGP_QA_CAUTION_CONTENT_1_1_2: '無法發起新揪團。',
  FGGP_QA_CAUTION_CONTENT_1_2: '一個活動一次只可以發起或者加入一個揪團。',
  FGGP_QA_CAUTION_CONTENT_1_3: '在揪團時效結束前，若您解散或退出揪團，將扣除信用積分並收取4%退票手續費用。',
  FGGP_QA_CAUTION_CONTENT_1_4: '冷卻期：發起/加入或解散/退出揪團後，需要一小時冷卻期才可以重新使用揪團功能。',
  FGGP_QA_CAUTION_SUBTITLE_2: '流團與時間限制',
  FGGP_QA_CAUTION_CONTENT_2_1_1: '揪團時效：自開團日起＋3日。',
  FGGP_QA_CAUTION_CONTENT_2_1_2: '當揪團時效結束而揪團未滿員，則視為流團，系統將自動全員退款。',
  FGGP_QA_CAUTION_CONTENT_2_2: '發起人若自行解散揪團，則其他成員亦視為流團。',
  FGGP_QA_CAUTION_SUBTITLE_3: '什麼是信用積分？',
  FGGP_QA_CAUTION_CONTENT_3_1: '若您多次解散或退出揪團導致信用積分過低，將不能使用發起揪團功能。',
  FGGP_QA_CAUTION_CONTENT_3_2: '加入揪團或自行購票一次即可恢復發起揪團功能。',
  FGGP_QA_CAUTION_SUBTITLE_4: '揪團系統服務費',
  FGGP_QA_CAUTION_CONTENT_4_1: '本功能將收取系統服務費。',
  FGGP_QA_CAUTION_CONTENT_4_2: '若主動解散揪團或退出揪團，系統將收取4％',
  FGGP_QA_CAUTION_CONTENT_4_2_1: '退票手續費用。',
  FGGP_QA_CAUTION_CONTENT_4_3: '若發生流團或被主揪解散，系統將自動退還票價與系統服務費。',
  FGGP_QA_CAUTION_SUBTITLE_5: '揪團成功與分發票券',
  FGGP_QA_CAUTION_CONTENT_5_1: '當揪團成功滿員直到揪團時效結束，系統將自動分發票券。',
  FGGP_QA_CAUTION_CONTENT_5_2: '揪團成功完成票券分發後將無法退票。',
  FGGP_RULE: '揪團規則',
  FGGP_RULE_1: '我已了解 ',
  FGGP_RULE_2: '，並同意上述內容 。',
  FGGP_MEMO: '留一句加速揪團（無法修改)',
  FGGP_MEMO_RULE: '(限20個字)',
  FGGP_TICKET_SEL: '你的購買張數',
  FGGP_TICKET_GOAL: '成團張數',
  FGGP_DETAIL: '揪團詳情',
  FGGP_NO_OF_PEOPLE: '人數',
  FGGP_IN_GROUP: '揪團中',
  FGGP_OWNER: '揪團人',
  FGGP_MEMBER: '揪團成員',
  FGGP_JOIN: '加入揪團',
  FGGP_JOINED: '已加入揪團',
  FGGP_DISBAND_GROUP: '解散揪團', 
  FGGP_DISBAND: '解散', 
  FGGP_DISBAND_NOTE_1: '主動解散揪團，將影響信用積分並收取4%退票手續費。', 
  FGGP_DISBAND_NOTE_2: '完成退出後，需要間隔一小時冷卻期才可以重新使用揪團功能。', 
  FGGP_LEAVE: '退出', 
  FGGP_LEAVE_GROUP: '退出揪團',  
  FGGP_COMPLETE_GROUP: '完成訂單',  
  FGGP_LEAVE_NOTE_1: '主動退出揪團，將影響信用積分並收取4%退票手續費。',  
  FGGP_LEAVE_NOTE_2: '完成退出後，需要間隔一小時冷卻期才可以重新使用揪團功能。',  
  FGGP_NOBODY: '目前還沒有人加入',
  FGGP_NOBODY_HINT: '快點加入揪團吧！',
  FGGP_CHECKOUT: '你即將進入結帳頁面',
  FGGP_CHECKOUT_HINT: '請放心，如果逾時未能成團，系統會自動為您全額退款。',
  FGGP_SHARE: '分享加速揪團',
  FGGP_SHARE_HINT: '透過分享，邀請朋友購票！',
  FGGP_SHARE_NOTE: '【揪團優惠即將滿員】，FANSI GO為您輕鬆湊齊團購票券優惠，就差你一個！',
  FGGP_SPECIAL_OFFER: '揪團多人票',
  FGGP_MERCHANDISE: '商品販售',
  FGGP_MORE: '看更多揪團優惠票',
  FGGP_INPUT_PLACEHOLDER: '已購買，小孩愛吃！',
  FGGP_COUNTDOWN_NOTE: '請等待揪團結果',
  FGGP_FULL_MEMBER_NOTE: '等倒數結束後，就開始分發票券',
  FGGP_ERROR_CREDIT: '信用積分不足',
  FGGP_ERROR_CREDIT_NOTE: '您因信用積分不足限制揪團功能\n請嘗試自行購票或加入揪團以恢復積分。',
  FGGP_ERROR_TICKET: '無符合的票種',
  FGGP_ERROR_TICKET_NOTE: '已無符合揪團的票種',
  FGGP_ERROR_PAYMENT: '仍有待付款',
  FGGP_ERROR_PAYMENT_NOTE: '尚有未付款訂單請先完成訂單。',
  FGGP_ERROR_EXPIRE: '超過時效',
  FGGP_ERROR_EXPIRE_NOTE: '該票種將結束販售，已超出發起揪團時效...',
  FGGP_ERROR_LIMIT: '揪團限制',
  FGGP_ERROR_LIMIT_NOTE: '一個活動一次只能發起或加入一個揪團',
  FGGP_TICKET_MAX_WARNING: '已達您可購票上限',
  'JOIN-GROUP-ERR-23': '冷卻期',
  'JOIN-GROUP-ERR-23-Note': '您剛解散或退出揪團，請在一小時後重新嘗試。',
  'JOIN-GROUP-ERR-16': '已經滿團',
  'JOIN-GROUP-ERR-16-Note': '其他人搶先一步結帳中',


  //Home
  HP_EVENT_RUNNING: '活動進行中',
  HP_EVENT_ENDED: '活動已結束',
  HP_EVENT_RECENT: '進行中活動',
  HP_EVENT_RECENT_SUBTITLE: '正在進行的精彩不容錯過',
  HP_EVENT_PASSED: '已結束的活動',
  HP_EVENT_PASSED_SUBTITLE: '你的足跡將為你帶來獨家優惠',
  HP_CHK_EVENT: '查看活動任務',
  HP_MERCH_RUNNING: '加購活動',
  HP_MERCH_RECENT: '進行中的加購',
  HP_MERCH_RECENT_SUBTITLE: '正在進行的精彩不容錯過',
  HP_MERCH_PASSED: '已結束的加購',
  HP_MERCH_PASSED_SUBTITLE: '你的足跡將為你帶來獨家優惠',


  //footer
  FOOTER_PWA_INSTALL: '建立 FANSI GO 捷徑到手機桌面，讓你更方便開啟喔！',
  FOOTER_PWA_IOS: '請使用Safari建立 FANSI GO 捷徑',
  FOOTER_PWA_IOS_INFO1: '點擊下方',
  FOOTER_PWA_IOS_INFO2: '然後選擇『加入主畫面』',
  FOOTER_PWA_Create_ShortCut: '建立捷徑',
  FOOTER_HP: '首頁',
  FOOTER_Collection: '個人蒐藏',

  //header
  HEADER_MY_CORP: '商戶管理',

  //System
  SYS_CHK_NOW: '立即查看',
  SYS_Quest_notice: '你有 {0} 個新任務，快來看看！',

  //領票連結
  APP_TICKET_CLAIM: '領取票券',
  APP_TICKET_CLAIMED: '領取成功',
  APP_TICKET_CHECK: '到”票券”確認一下',
  APP_TICKET_CLAIM_NOW: '立即領取',
  APP_TICKET_INVALID: '連結已經失效',
  APP_TICKET_INVALID_INFO: '連結可能已被領取或者錯誤\n請確認連結來源或活動主辦方 ',   
  APP_TICKET_LOGIN: '登入後即可領取\n建議使用Chrome瀏覽器',
  APP_TICKET_ACCOUNT_HINT: '這不是你常用的 FANSI GO帳號 ?', 
  APP_TICKET_ACCOUNT_CONFIRM: '請留意帳號是否正確',

  //通用
  Close: '關閉',
  Cancel: '取消',
  Cancel_Order: '取消訂單', 
  Cancel_Order_Confirm: '確認取消訂單',
  Close_Window: '關閉視窗',
  Copied: '已複製',
  detail_info: '詳細資訊',
  Edit: '編輯',
  Expired: '已失效',  
  Order_Id: '訂單編號',
  Purchased_Date: '訂購時間',
  Redeem: '查看',
  Save: '儲存',
  more: '更多',
  Sold_Out: '已售完',
  Ticket_Due: '使用期限',
  Refund_Due: '退票期限',
  Input: '輸入',
  Show_Less: '顯示更少',
  Show_More: '顯示更多',
  Confirm: '確定',
  Unit: '單位',
  Back_To_Homepage: '回到首頁',
  Not_Now: '先不要',
  Please_Select: '請選擇',
  Continue: '繼續',
  NextStep: '下一步',
  Scan_Again: '重新掃描',
  Send: '送出',
  Logout: '登出',
  Please_Note: '請注意',
  Loading_Msg: '資訊處理中，請稍後...',
  Got_It: '我知道了',
  Recommand: '推薦',
  Return: '返回',
  Back_To_Top: '回頁面上方',
  About_To_Drop: '即將開賣',
  Up_For_Grabs: '熱賣中',
  Too_Late: '你已太晚',
  Login: '登入',
  Login_With_Google: '使用Google帳號登入',
  Other_Login: '使用其他方式登入',
  Header_Slogan: '你的每個參與都有價值',
  Acquired_On: '取得時間',
  COMMON_ERROR_TITLE: '資訊錯誤',
  TRANSACTION: '交易',
};

// Placeholder english dictionary.
export const en = {
  ...zh,
  About: 'About',
  Login_N_Agree:'By clicking “Login", you have reviewed and agreed \n',
  Terms_of_Service: 'Terms of Service',
  Privacy_Policy: 'Privacy Policy',
  WEB_QA:'FAQ',

  //Special
  FG_FANSILAND_INFO: 'You\'ll receive an e-ticket redeem email within 3-5 business days.',

  //票劵頁面
  FGT_Select_Show: 'Please select the event',
  FGT_Show_List: 'Events',
  FGT_Event_Info: 'Event details',
  FGT_Pick_Section: 'Select tickets',
  FGT_Sale_End: 'close at',
  FGT_Sale_Start_At: 'Sales begin',
  FGT_Not_Sale_Yet: 'Coming soon',
  FGT_Add_Calendar:'+ Calendar',
  FGT_Section_Usage: 'Valid dates',
  FGT_Section_Info: 'Ticket details',
  FGT_Ticket_Purchased: 'You have purchased {0} tickets.',
  FGT_Ticket_Available: 'You can buy {0} more tickets.',
  FGT_Show_Purchased_Max: 'Maximum tickets per event reached.',
  FGT_Purchased_Max: 'Purchase limit reached.',
  FGT_Whitelist_Warning:'Not qualified',
  FGT_Sale_Start: 'The sales will start at',
  FGT_Back_To_Event_Page:'Back',
  FGT_Purchase_Now: 'Buy now',
  FGT_Uncomplete_Order:'Unfinished order. Continue?',
  FGT_Purchase_Limit_Info:'Purchase limit {0} tickets per event.',  
  FGT_SEC_Tickets_Remaion:'（{0} tickets left!）',
  FGT_Select_Seat:'Seat selection',
  FGT_Reset_Seat:'Reselect all',
  FGT_No_Seat:'Seat not selected',
  FGT_Select_Seat_Result:'Selected seats',
  FGT_Select_No:'Ticket quantity',
  FGT_Seat_Section:'Seat Area',
  FGT_Seat_Info:'Visual guide',
  FGT_Seat_Empty:'Open seat',
  FGT_Seat_Selected:'Selected',
  FGT_Seat_Sold:'Sold',
  FGT_Seat_Confirm:'Confirm',
  FGT_Seat_Cancel:'Cancel',
  FGT_Seat_Current:'Current selection',
  FGT_Seat_Cancel_Current:'Cancel?',
  FGT_Remain:'Reserve',
  FGT_Seat_Reset:'Reselect all',
  FGT_Seat_Checkout:'Click to Confirm Seat Info.',
  "FGRT-ERR-2":'Reservation failed. Please retry',
  "FGRT-ERR-3":'Limit exceeded',
  "FGRT-ERR-3-NOTE":'Exceeds Purchase Limit for This Event.',
  "FGRT-ERR-4":'Low ticket availability',
  "FGRT-ERR-5":'Invalid order. Please retry',
  "FGRT-ERR-6":'Unpaid orders remain. Please ',
  "FGRT-ERR-6-1":'complete payment.',
  "FGT-ReserveSeat-11":'Seat selection error',
  "FGT-ReserveSeat-12":'Seat selection error',
  "FGT-ReserveSeat-13":'Seat already taken',
  "FGT-ReserveSeat-14":'Seat selection error',

  //payment page
  "FGRD-ERR-11": 'Invalid code. Please re-enter.',
  "FGRD-ERR-12": 'Code has been used.',
  "FGRD-ERR-13": 'Code cannot be used for this event.',
  "FGRD-ERR-14": 'Code expired.',
  "FGRD-ERR-15": 'Usage limit exceeded.',
  "FGRD-ERR-16": 'Ahh you\'re too late, coupon code has been fully redeemed.',
  "FGRD-ERR-21": 'Something\'s wrong. Please try again.',
  FGPP_Handling: 'Fee',
  FGPP_Line_Pay: 'Mobile pay. ',
  FGPP_Aftee: ' ',
  FGPP_Aftee_Pay: 'Store/ATM/transfer. ',
  FGPP_Paypal_Pay:'International payment. ',
  FGPP_Cash:'Pay on-site',
  FGPP_Cash_Pay:'Cash payment. ',
  FGPP_CreditCard:'Credit Card',
  FGPP_CreditCard_Pay:'Pay by credit card.',
  FGPP_Crypto_Pay: 'Crypto payment. ',
  FGPP_Payment_Completed: 'Payment completed.',
  FGPP_Ticket:'Checkout',
  FGPP_Free: 'Free tickets',
  FGPP_Payment_Method: 'Payment',
  FGPP_Payment_Warning: 'Order\'s on hold. Please complete the purchase within 15 minutes to avoid cancellation.',
  FGPP_Checkout_Warning: 'No changes after checkout.',
  FGPP_Billing_Detail: 'Amount details',
  FGPP_Third_Party_Handling:'Service fee',
  FGPP_Third_Party_Handling_Note: 'Service fee includes tickets handling and payment processing fees.',
  FGPP_Notice: 'Notice',
  FGPP_Note_1: 'Please confirm your order details and prices. No ticket exchanges available.',
  FGPP_Note_2: 'For multiple tickets, use the split feature to enter separately.',
  FGPP_Note_3: 'This system uses QR Code e-tickets. The QR Code will be displayed 24 hours before the event starts.',
  FGPP_Note_4: 'Refund Deadline: ',
  FGPP_Note_4_1: 'Within 3 days of purchase. ',
  FGPP_Note_4_2: 'If the show is in less than 3 days, the deadline is 23:59 the day before the show.',
  FGPP_Note_12: 'Add-On Refunds: The deadline is 23:59 the day before the show, per organizer rules. See details in the activity description.',
  FGPP_Note_13: 'Disbanding or exiting a group incurs a 4% cancellation fee, while failed groups receive a full refund.',
  FGPP_Note_5: 'Refund policy: ',
  FGPP_Note_6: 'Line Pay: 4% refund fee will be charged, processed immediately.',
  FGPP_Note_7: 'AFTEE: Full refund, refund processed within 14 business days',
  FGPP_Note_8: 'PayPal: Full ticket refund, excluding a 8% transaction fee.',
  FGPP_Note_9: 'USDT(Polygon): 4% refund fee will be charged, refund processed within 14 business days',
  FGPP_Note_10: 'Cash payment: Non-refundable.',
  FGPP_Note_11: 'Credit Card: 4% refund fee will be charged, processed immediately.',
  FGPP_Refund_Note: 'Refund Policy',
  FGPP_Confirm_Order_Info: 'Order Details',
  FGPP_Total_Amount: 'Total Amount',
  FGPP_Next_Step_Warning: 'By clicking "Next", you have reviewed and agreed',
  FGPP_Pre_Step: 'Back',
  FGPP_Enter_Checkout: 'Checkout',
  FGPP_Payment_Refund_Warning: 'Please be aware of refund valid time and fees may not be refunded.',
  FGPP_QRcode_Warning: 'Please show the QR code to satff for scanning',
  FGPP_Cash_Confirm: 'Check Result',
  FGPP_Cash_Confirm_Warning: 'After the payment is made, click "Check Result".',
  FGPP_Payment_Uncomplete: 'Payment pending',
  FGPP_Payment_Method_Error: 'Payment Error',
  FGPR_Aftee_Warning: `Order confirmed. Please pay within AFTEE's deadline to avoid late fees.`,
  FGPP_Order_Expired:'Order overdue.Please try again.',
  FGPP_Start_Checkout: 'Checkout',
  FGPP_NextStep_Warning: `Click Checkout to confirm \nyour order info`,
  FGPP_SEAT_INFO:'Seat Info',
  FGPP_SEAT:'Seat',
  FGPP_Aftee_Warning_1:'AFTEE paid. Settle soon or face late fees.',
  FGPP_Aftee_Warning_2_1:'No auto-cancel for unpaid orders.',
  FGPP_Aftee_Warning_2_2:'Please check and clear your AFTEE balance.',
  FGPP_Aftee_Warning_3:'Refunds: Use FANSI GO tickets page in valid period.',
  FGPP_User_Login_Warning:'Log in to the original account to pay',
  FGPP_Change_Account:'Switch accounts',
  FGPP_Wrong_Account:'Account mismatch',

  //payment result
  FGPR_Payment_Complete: 'Order Complete!',
  FGPR_Payment_Complete_Semi: 'Order status subject to transaction history.',
  FGPR_Crypto_Warning: 'Please note that the payment will only be completed upon blockchain verification.',
  FGPR_Ticket_Confirm:'Check "Tickets"',
  FGPR_Trans_Fail:'Transaction failed.Try again.',

  //票夾頁面
  FGC_Ticket: 'Ticket',
  FGC_Ticket_No: 'Ticket No.',
  FGC_Original_Price: 'Original price',
  FGC_Ticket_Not_Own:'Return all tickets to refund.',
  FGC_Refund: 'Refund',
  FGC_Confirm_Refund:'Confirm',
  FGC_Refund_Fail:'Failed',
  FGC_Not_Refundable:'Period expired',
  FGC_Not_Aavailable: 'Not available',
  FGC_Event_Ended: 'Event ended',
  FGC_Redeemed: 'Redeemed',
  FGC_Not_Redeem: 'Unredeemed',
  FGC_Barcode_Notice1: 'Disable dark mode. Use white background. Ensure high contrast.',
  FGC_Barcode_Notice2:'Adjust your screen brightness.',
  FGC_Barcode_Notice3: 'QR Code will be available 24 hours before the event.',
  FGC_Owner: 'Name',
  FGC_My_Transactions:'Order History',
  FGC_Zero_Ticket_Notice1: "Don't have tickets yet.",
  FGC_Zero_Ticket_Notice2:'Tickets will be displayed here.',
  FGC_Ticket_Tax_No: 'Compliance No.',

  //分票
  FGC_Share:'Split the ticket',
  FGC_Generate_Link:'Generate the ticket link',
  FGC_Generating:'Loading...',
  FGC_Share_Warning:`Be mindful of the recipient; tickets can't be retrieved or refunded unless returned. Once claimed, your ticket moves to the recipient's account.`,
  FGC_Copied:'Copied',
  FGC_Return_Ticket:'Return ticket',
  FGC_Return_Ticket_Info:'Return the ticket to the purchaser.',
  FGC_Return_Success:'Return completed.',

  //折扣頁面
  FGD_Discount: 'Discount',
  FGD_Discount_Expired: 'Discount Expired',
  FGD_Expire_On: 'Expire on',
  FGD_Coupon_Code:'Coupon Code ',
  FGD_Coupon_Warning: 'Please note that only one coupon code available per order.',
  FGD_Zero_Coupon: 'No coupons available.',
  FGD_Zero_Coupon_Info: 'Stay tuned for benefits updates; we will notify you promptly.',
  FGD_Coupons:'Coupons',
  FGD_OnGoing:'Available',
  FGD_Expired:'Expired',
  FGD_My_Coupons:'My coupons',
  FGD_My_Coupon_Info:'Never miss a discount',

  //足跡頁面
  FGP_Badge_Info: 'Badge info',
  FGP_Claimed_Date: 'Collected date',
  FGP_Unclaim: 'Not collected',
  FGP_Event_Holder: 'Organizer',
  FGP_Badge_Collection: 'Badge Collection',
  FGP_Footprints:'Footprints',
  FGP_Goal_Achieve:'Achievements',
  FGP_My_Footprints:'Footprints',
  FGP_Treasure_Hunt:'Treasure hunt',
  FGP_No_Footprints:'Currently no footprints.',
  FGP_My_Footprints_Note:'Your activity footprints are displayed here.',

  //個人資訊
  FGU_Edit_Profile: 'Edit profile',
  FGU_Profile:'Profile',
  FGU_Display_Name: 'Display name',
  FGU_Real_Name: 'Legal name',
  FGU_Explorer: 'Explorer',
  FGU_Footprint: 'Footprint',
  FGU_Ticket: 'Ticket',
  FGU_Coupon_List:'Coupon',
  FGU_Personal_Info: 'User info',
  FGU_Name_Warning: 'Please enter your legal name for entry verification.',
  FGU_Gender:'Gender',
  FGU_Birthday:'Birthday',
  FGU_Phone:'Phone Number',
  FGU_Member_Card:'My Pass',
  FGU_Member_Card_Title:'Privilege Pass',
  FGU_Member_Card_Fill:'Fill out below',
  FGU_Member_General:'General member',
  FGU_Member_Unapproved:'Not approved yet.',
  FGU_Member_Activate:'Activate',
  FGU_Member_Date:'Activate date',
  FGU_Member_Point:'會員集點',
  FGU_Member_Point_Warning:'請再次確認集點金額',
  FGU_Member_Pos_Amount:'消費金額',
  FGU_Member_Neg_Amount:'沖銷金額',
  FGU_Member_Point_Pos:'切換回正數',
  FGU_Member_Point_Neg:'切換成負數',
  FGU_Member_Flip_Back: '詳細',
  FGU_Member_Flip_Front: '返回正面',
  FGU_Upload_Avatar: 'Upload Avatar',
  FGU_System_Mail: 'System Emails',

  //交易頁面
  FGTR_Transaction_Fail:'Failed',
  FGTR_Transaction_Completed:'Completed',
  FGTR_Wait_Confirm:'Confirming',
  FGTR_Wait_Payment:'Pending',
  FGTR_Cancel_Order:'Cancelled',
  FGTR_Refunded: 'Refunded',
  FGTR_Show: 'Events',
  FGTR_Section: 'Tickets',
  FGTR_Order_Id: 'Order ID',
  FGTR_Amount: 'Amount',
  FGTR_Order_Date: 'Order date',
  FGTR_My_Transaction:'Order History',
  FGTR_Zero_Order1: 'No order history.',
  FGTR_Zero_Order2: 'Oreders history will be displayed here.',
  FGTR_Payment_Method: 'Select payment method',
  FGTR_My_Ticket:'Tickets',
  FGTR_Ticket_Note:'At your fingertips.',

  //Event Info
  FGEI_Organizer:'Organizer',
  FGEI_Ranking:'Ranking',
  FGEI_Ur_Rank:'Your Rank',
  FGEI_Leaderboard:'Leaderboard',
  FGEI_Leaderboard_Notes:`The leaderboard follows the organizer's event rules.`,
  FGEI_Score:'My Score',
  FGEI_Score_Record:'Score Record',
  FGEI_Persional_Score_Record:'Score Record',
  FGEI_Introduction:'Event Info',
  FGEI_Event_Score:'Event Score',
  FGEI_Event_Score_Fixed: 'Offset Score',
  FGEI_Score_Notes:`The Event Score follows the organizer's event rules.`,
  FGEI_Badges:'Badges',
  FGEI_Badges_Notes:'Badges acquisition follows event rules.',
  FGEI_No_Event:'No badges have been generated yet.',
  FGEI_No_Event_Notes:'Stay tuned for immediate task notifications.',
  FGEI_Available:'Available',
  FGEI_Obtained:'Obtained',
  FGEI_Unobtained:'Unobtained',
  FGEI_Event_Info:'Badge Info',
  FGEI_Share_Now:'Share Now',
  FGEI_Badge_Notes:`Badge usage follows follows the organizer's event rules.`,
  FGEI_Share_Event:'Share Now',
  FGEI_Share_To:'Share to',
  FGEI_Copy_Link:'Copy',
  FGEI_Share_Notes: 'Fan power: Share tickets,pack the house!',
  FGEI_Share_Img: 'Share Gig Token',
  FGEI_Ticket: 'Gig Token',
  FGEI_Download_Ticket_Note:'Download&Share NOW!',
  FGEI_Download_Ticket:'Download',

  //group purchase
  FGGP_ALL_GROUP_PURCHASE:'Group Savers',
FGGP_WHAT_IS_GROUP_PURCHASE:`What's Group Saver`,
FGGP_TICKET:'Ticket(s)',
FGGP_TOTAL:'Total Price',
FGGP_NUMBER_OF_TICKET:'Number of Tickets',
FGGP_COMPLETE:'Group Formed',
FGGP_JOIN_NOW:'Join Now',
FGGP_TICKET_SORTING:'Full',
FGGP_CREATE_BTN:'Start a Group Now',
FGGP_CREATE:'Start a Group',
FGGP_QA_SUBTITLE_1:`Want to Enjoy Group Ticket Discounts but Can't Find Partners?`,
FGGP_QA_CONTENT_1:'Group ticket discounts require coordinating people, time, and costs, which can be complicated and risky.',
FGGP_QA_SUBTITLE_2:'How to Easily Group Purchase?',
FGGP_QA_CONTENT_2:'Our Group Ticketing feature helps match members, with each person paying separately, and the system automatically distributes tickets, saving communication costs and pickup risks.',
FGGP_QA_SUBTITLE_3:'What Other Benefits?',
FGGP_QA_CONTENT_3:'Easily enjoy discounted ticket prices and support your favorite music or event, allowing more people to participate together.',
FGGP_QA_CAUTION:'👉🏻 Notes 👈🏼',
FGGP_QA_CAUTION_SUBTITLE_1:'Rules for Initiating and Exiting',
FGGP_QA_CAUTION_CONTENT_1_1_1:'New group bookings cannot be initiated',
FGGP_QA_CAUTION_CONTENT_1_1_2:'within 7 days before the event starts.',
FGGP_QA_CAUTION_CONTENT_1_2:'You can initiate or join only one group booking per event at a time.',
FGGP_QA_CAUTION_CONTENT_1_3:'Exiting or disbanding a group before it expires incurs a 4% fee and credit score deduction.',
FGGP_QA_CAUTION_CONTENT_1_4:'Cooldown Period: After initiating/joining or disbanding/exiting a group, you must wait 1 hour before using the Group Saver feature again.',
FGGP_QA_CAUTION_SUBTITLE_2:'Expired Groups and Time Limits',
FGGP_QA_CAUTION_CONTENT_2_1_1:'Group Booking Validity: A group booking lasts for 3 days from the initiation date.',
FGGP_QA_CAUTION_CONTENT_2_1_2:'If the group does not reach the required number of members by the expiration time, it will be deemed unsuccessful, and all participants will be automatically refunded.',
FGGP_QA_CAUTION_CONTENT_2_2:'If the initiator disbands the group, the other members will also be considered part of an unsuccessful group.',
FGGP_QA_CAUTION_SUBTITLE_3:'What is Credit Score?',
FGGP_QA_CAUTION_CONTENT_3_1:'Repeatedly disbanding or exiting groups may lower your credit score, preventing you from initiating new group bookings.',
FGGP_QA_CAUTION_CONTENT_3_2:'Joining a group or purchasing tickets independently will restore your ability to initiate group bookings.',
FGGP_QA_CAUTION_SUBTITLE_4:'Group Saver System Service Fee',
FGGP_QA_CAUTION_CONTENT_4_1:'A system service fee applies to using this feature.',
FGGP_QA_CAUTION_CONTENT_4_2:'Failed groups or initiator disbandment will trigger automatic refunds for tickets and service fees.',
FGGP_QA_CAUTION_SUBTITLE_5:'Successful Group Booking and Ticket Distribution',
FGGP_QA_CAUTION_CONTENT_5_1:'Once a group reaches the required number of members and remains so until the expiration time, tickets will be automatically distributed.',
FGGP_QA_CAUTION_CONTENT_5_2:'After tickets have been successfully distributed, no refunds or cancellations will be allowed.',
FGGP_RULE:'I Understand',
FGGP_RULE_1:' the Group Rules',
FGGP_RULE_2:',and Agree to the Above.',
FGGP_MEMO:'Add a Message to Speed Up Group (Cannot be Modified)',
FGGP_MEMO_RULE:'(Limited to 20 Characters)',
FGGP_TICKET_SEL:'Number of Tickets',
FGGP_TICKET_GOAL:'Group Formation Ticket Count',
FGGP_DETAIL:'Group Details',
FGGP_NO_OF_PEOPLE:'Number of People',
FGGP_IN_GROUP:'In Group',
FGGP_OWNER:'Initiator',
FGGP_MEMBER:'Members',
FGGP_JOIN:'Join Group',
FGGP_JOINED:'Already Joined',
FGGP_DISBAND_GROUP:'Dissolve',
FGGP_DISBAND:'Dissolve',
FGGP_DISBAND_NOTE_1:'Voluntarily dissolving the group will affect credit points and incur a 4% ticket cancellation fee.',
FGGP_DISBAND_NOTE_2:'After completing exit, a one-hour cooldown is required before reusing the group feature.',
FGGP_LEAVE:'Leave',
FGGP_LEAVE_GROUP:'Leave Group',
FGGP_COMPLETE_GROUP:'Complete Order',
FGGP_LEAVE_NOTE_1:'Voluntarily leaving the group will affect credit points and incur a 4% ticket cancellation fee.',
FGGP_LEAVE_NOTE_2:'After completing exit, a one-hour cooldown is required before reusing the group feature.',
FGGP_NOBODY:'No One Has Joined Yet.',
FGGP_NOBODY_HINT:' Join the Group Now!',
FGGP_CHECKOUT:'You Are About to Enter the Checkout Page',
FGGP_CHECKOUT_HINT:'Rest assured, if the group fails to form within the time limit, the system will automatically provide a full refund.',
FGGP_SHARE:'Share to Accelerate.',
FGGP_SHARE_HINT:'Share and Invite Friends to Purchase Tickets!',
FGGP_SHARE_NOTE:'【Group Discount Almost Full】FANSI GO helps you easily gather group ticket discounts, just need you!',
FGGP_COOLDOWN:'Cooldown Period',
FGGP_COOLDOWN_NOTE:'You just dissolved or left a group. Please try again after an hour.',
FGGP_SPECIAL_OFFER:'Group Multi-Person Tickets',
FGGP_MORE:'See More Group Discount Tickets',
FGGP_INPUT_PLACEHOLDER:'Purchased, Kids Love It!',
FGGP_COUNTDOWN_NOTE:'Please Wait for Group Result',
FGGP_FULL_MEMBER_NOTE:'Ticket Distribution Starts After Countdown Ends',
FGGP_ERROR_CREDIT:'Insufficient Credit Points',
FGGP_ERROR_CREDIT_NOTE:'Group feature restricted due to low credit points\nTry purchasing individually or joining a group to restore points.',
FGGP_ERROR_TICKET:'No Matching Ticket Types',
FGGP_ERROR_TICKET_NOTE:'No Ticket Types Available for Group',
FGGP_ERROR_PAYMENT:'Pending Payment',
FGGP_ERROR_PAYMENT_NOTE:'Incomplete Orders - Please Finish Existing Orders First',
FGGP_ERROR_FULL:'Group Already Full',
FGGP_ERROR_FULL_NOTE:'Others Checking Out Ahead...',
FGGP_ERROR_EXPIRE:'Time Limit Exceeded',
FGGP_ERROR_EXPIRE_NOTE:'Ticket Type Sales Ending, Group Initiation Time Limit Passed...',
FGGP_ERROR_LIMIT:'Group Restrictions',
FGGP_ERROR_LIMIT_NOTE:'Only One Group Can Be Started or Joined per Event',
FGGP_TICKET_MAX_WARNING:'Reached Your Ticket Purchase Limit',

  //Home
  HP_EVENT_RUNNING:'Ongoing',
  HP_EVENT_ENDED:'Ended',
  HP_EVENT_RECENT:'Upcoming',
  HP_EVENT_RECENT_SUBTITLE: `Don't miss any you may like.`,
  HP_EVENT_PASSED:'Past',
  HP_EVENT_PASSED_SUBTITLE:'Your footprints earn you perks.',
  HP_CHK_EVENT:'Badge Event',

  //footer
  FOOTER_PWA_INSTALL: 'Create a FANSI GO shortcut for easy access!',
  FOOTER_PWA_IOS: 'Create a FANSI GO shortcut on Safari.',
  FOOTER_PWA_IOS_INFO1: 'Click the button below,',
  FOOTER_PWA_IOS_INFO2: 'select "Add to main screen"',
  FOOTER_PWA_Create_ShortCut:'Shortcut',
  FOOTER_HP:'Home',
  FOOTER_Collection: 'Collection',  

  //header
  HEADER_MY_CORP:'Merchant interface',

  //System
  SYS_CHK_NOW:'Check now',
  SYS_Quest_notice:'You have {0} new quests!',

  //通用
  Close: 'Close',
  Cancel: 'Cancel',
  Cancel_Order:'Cancel',
  Cancel_Order_Confirm:'Confirm',
  Close_Window: 'Close window',
  Copied: 'Copied',
  detail_info: 'Detail info',
  Edit: 'Edit',
  Expired: 'Expired',
  Order_Id: 'Order ID',
  Purchased_Date: 'Purchased date',
  Redeem:'Check',
  Save: 'Save',
  more:'More',
  Sold_Out: 'Sold out',
  Ticket_Due: 'Expiration date',
  Refund_Due: 'Refund before',
  Input:'Enter ',
  Show_Less: 'Show less',
  Show_More: 'Show more',
  Confirm: 'Confirm',
  Unit:'Currency',
  Back_To_Homepage: 'Home',
  Not_Now: 'Not now',
  Please_Select:'Select',
  Continue: 'Continue',
  NextStep: 'Next',
  Scan_Again:'Scan Again',
  Send:'Send',
  Logout: 'Logout',
  Please_Note:'Note',
  Loading_Msg:'Loading...',
  Got_It:'Got it',  
  Recommand:'New',
  Return:'Back',
  Back_To_Top:'Back to top',
  About_To_Drop: 'About to drop',
  Up_For_Grabs: 'Up for grabs',
  Too_Late: 'You’re late to the party',
  Login: 'Login',
  Login_With_Google: 'Login with Google',
  Other_Login: 'Another login method',
  Header_Slogan: 'Your participation is valuable',
  Acquired_On: 'Acquired on',
};
