import { ReactComponent as AppleStore } from '../components/img/img-apple-store.svg';
import { ReactComponent as AlertSquare } from '../components/img/icon-alert-square.svg';
import { ReactComponent as AlertSquareG } from '../components/img/icon-alert-square-gray.svg';
import { ReactComponent as AlertSquareT } from '../components/img/icon-alert-square-teal.svg';
import { ReactComponent as AlertSquareR } from '../components/img/icon-alert-square-red.svg';
import { ReactComponent as ArrowDown } from '../components/img/icon-arrow-down.svg';
import { ReactComponent as ArrowDownB } from '../components/img/icon-arrow-down-black.svg';
import { ReactComponent as ArrowDownBlue } from '../components/img/icon-arrow-down-blue.svg';
import { ReactComponent as ArrowDownT } from '../components/img/icon-arrow-down-teal.svg';
import { ReactComponent as ArrowLeft } from '../components/img/icon-arrow-left.svg';
import { ReactComponent as ArrowLeft2 } from '../components/img/icon-arrow-left-2.svg';
import { ReactComponent as ArrowLeftBa } from '../components/img/icon-arrow-left-black.svg';
import { ReactComponent as ArrowRight } from '../components/img/icon-arrow-right.svg';
import { ReactComponent as ArrowRight2 } from '../components/img/icon-arrow-right-2.svg';
import { ReactComponent as ArrowRightBa } from '../components/img/icon-arrow-right-black.svg';
import { ReactComponent as ArrowRightTeal } from '../components/img/icon-arrow-right-teal.svg';
import { ReactComponent as ArrowUp } from '../components/img/icon-arrow-up.svg';
import { ReactComponent as ArrowUpB } from '../components/img/icon-arrow-up-blue.svg';
import { ReactComponent as ArrowUpBlack } from '../components/img/icon-arrow-up-black.svg';
import { ReactComponent as ArrowUpR } from '../components/img/icon-arrow-up-red.svg';
import { ReactComponent as Chair } from '../components/img/icon-chair.svg';
import { ReactComponent as Clock } from '../components/img/icon-clock-white.svg';
import { ReactComponent as ClockSquare } from '../components/img/icon-clock-square.svg';
import { ReactComponent as Close } from '../components/img/btn-bgc-close.svg';
import { ReactComponent as CloseBlack } from '../components/img/btn-bgc-close-black.svg';
import { ReactComponent as CloseC } from '../components/img/icon-close-clear.svg';
import { ReactComponent as CloseOrange } from '../components/img/btn-bgc-close-orange.svg';
import { ReactComponent as Collection } from '../components/img/icon-collection.svg';
import { ReactComponent as CollectionOrange } from '../components/img/icon-collection-orange.svg';
import { ReactComponent as Copy } from '../components/img/icon-copy.svg';
import { ReactComponent as Discord } from '../components/img/icon-discord.svg';
import { ReactComponent as DiscordB } from '../components/img/icon-discord-blue.svg';
import { ReactComponent as DeleteW } from '../components/img/icon-delete-white.svg';
import { ReactComponent as Download } from '../components/img/icon-download.svg';
import { ReactComponent as DrawerOpen } from '../components/img/drawer-open.svg';
import { ReactComponent as ExclamationBlack } from '../components/img/icon-exclamation-black.svg';
import { ReactComponent as ExclamationCircle } from '../components/img/exclamation-circle.svg';
import { ReactComponent as Explore } from '../components/img/icon-explore.svg';
import { ReactComponent as ExploreWhite } from '../components/img/icon-explore-white.svg';
import { ReactComponent as Fail } from '../components/img/icon-fail.svg';
import { ReactComponent as FANSI } from '../components/img/icon-fansi.svg';
import { ReactComponent as FANSIGO } from '../components/img/icon-FANSI-GO.svg';
import { ReactComponent as FANSIGO_I } from '../components/img/icon-FANSI-GO-invert.svg';
import { ReactComponent as FANSIGO_LOGO } from '../components/img/icon-FANSI-GO-logo.svg';
import { ReactComponent as FANSIGO_LOGO_Gray } from '../components/img/icon-FANSI-GO-logo-gray.svg';
import { ReactComponent as FANSIGO_S } from '../components/img/icon-fansi-go-small.svg';
import { ReactComponent as FB } from '../components/img/icon-facebook.svg';
import { ReactComponent as FBWhite } from '../components/img/icon-facebook-white.svg';
import { ReactComponent as Files } from '../components/img/icon-file.svg';
import { ReactComponent as Gear } from '../components/img/icon-gear.svg';
import { ReactComponent as Google } from '../components/img/icon-google.svg';
import { ReactComponent as GooglePlay } from '../components/img/img-google-play.svg';
import { ReactComponent as Hourglass } from '../components/img/icon-hourglass.svg';
import { ReactComponent as Hourglass2 } from '../components/img/icon-hourglass-2.svg';
import { ReactComponent as Home } from '../components/img/icon-home.svg';
import { ReactComponent as HomeOrange } from '../components/img/icon-home-orange.svg';
import { ReactComponent as IG } from '../components/img/icon-instagram.svg';
import { ReactComponent as IGGray } from '../components/img/icon-instagram-gray.svg';
import { ReactComponent as Info } from '../components/img/icon-info.svg';
import { ReactComponent as InfoBlue } from '../components/img/icon-info-blue.svg';
import { ReactComponent as InfoGray } from '../components/img/icon-info-gray.svg';
import { ReactComponent as InfoTeal } from '../components/img/icon-info-teal.svg';
import { ReactComponent as InfoWhite } from '../components/img/icon-info-white.svg';
import { ReactComponent as Link } from '../components/img/icon-link-white.svg';
import { ReactComponent as Linkedin } from '../components/img/icon-linkedin.svg';
import { ReactComponent as LinkTree } from '../components/img/icon-link-tree.svg';
import { ReactComponent as LinkTreeB } from '../components/img/icon-link-tree-blue.svg';
import { ReactComponent as ListW } from '../components/img/list_white.svg';
import { ReactComponent as Location } from '../components/img/icon-location.svg';
import { ReactComponent as LocationOrange } from '../components/img/icon-location-orange.svg';
import { ReactComponent as Lock } from '../components/img/icon-lock.svg';
import { ReactComponent as Logout } from '../components/img/icon-logout.svg';
import { ReactComponent as Logo } from '../components/img/FANSI_MB_logo.svg';
import { ReactComponent as LogoFull } from '../components/img/icon-fansi-logo-full.svg';
import { ReactComponent as Map } from '../components/img/map.svg';
import { ReactComponent as Medale } from '../components/img/icon-medale.svg';
import { ReactComponent as MedaleOrange } from '../components/img/icon-medale-orange.svg';
import { ReactComponent as Menu } from '../components/img/icon-menu.svg';
import { ReactComponent as Member } from '../components/img/icon-member-white.svg';
import { ReactComponent as MemberG } from '../components/img/icon-member-gray.svg';
import { ReactComponent as MemberO } from '../components/img/member_orange.svg';
import { ReactComponent as MetaBoom } from '../components/img/icon-MetaBoom.svg';
import { ReactComponent as NoEvent } from '../components/img/icon-no-active.svg';
import { ReactComponent as OpenSea } from '../components/img/icon-opensea.svg';
import { ReactComponent as Pause } from '../components/img/btn-pause.svg';
import { ReactComponent as Play } from '../components/img/btn-play.svg';
import { ReactComponent as Question } from '../components/img/icon-question.svg';
import { ReactComponent as QuestionTeal } from '../components/img/icon-question-teal.svg';
import { ReactComponent as Quote } from '../components/img/icon-quote.svg';
import { ReactComponent as QRCode } from '../components/img/icon-qr-code.svg';
import { ReactComponent as QRCodeBlack } from '../components/img/icon-qr-code-black.svg';
import { ReactComponent as Refresh } from '../components/img/icon-refresh-white.svg';
import { ReactComponent as RisingStars } from '../components/img/icon-rising-stars.svg';
import { ReactComponent as RoundCArrowLeft } from '../components/img/round-carousel-arrow-left.svg';
import { ReactComponent as RoundCArrowRight } from '../components/img/round-carousel-arrow-right.svg';
import { ReactComponent as Scan } from '../components/img/icon-scan.svg';
import { ReactComponent as ShareOrange } from '../components/img/icon-share-orange.svg';
import { ReactComponent as ShareTicket } from '../components/img/icon-share-ticket.svg';
import { ReactComponent as Shoeprint } from '../components/img/icon-shoeprints.svg';
import { ReactComponent as Star } from '../components/img/icon-doodle-star.svg';
import { ReactComponent as StarBlack } from '../components/img/icon-doodle-star-black.svg';
import { ReactComponent as StarGray } from '../components/img/doodle-star.svg';
import { ReactComponent as StarEmpty } from '../components/img/icon-star.svg';
import { ReactComponent as StarWhite } from '../components/img/star_white.svg';
import { ReactComponent as SeatClip } from '../components/img/icon-seat-clip.svg';
import { ReactComponent as SeatPath } from '../components/img/icon-seat-path.svg';
import { ReactComponent as Ticket } from '../components/img/icon-ticket.svg';
import { ReactComponent as TicketH } from '../components/img/icon-ticket-h.svg';
import { ReactComponent as TicketOrange } from '../components/img/ticket_orange.svg';
import { ReactComponent as Trashcan } from '../components/img/icon-trashcan.svg';
import { ReactComponent as TrophyBronze } from '../components/img/icon-trophy-bronze.svg';
import { ReactComponent as TrophyGold } from '../components/img/icon-trophy-gold.svg';
import { ReactComponent as TrophySilver } from '../components/img/icon-trophy-silver.svg';
import { ReactComponent as Twitter } from '../components/img/icon-twitter.svg'
import { ReactComponent as TwitterB } from '../components/img/icon-twitter-blue.svg'
import { ReactComponent as Wallet } from '../components/img/icon-wallet-white.svg';
import { ReactComponent as X } from '../components/img/icon-x.svg';

//non svg
import MM from '../components/img/icon-metamask.png';
import Safari from '../components/img/icon-Safari.png';

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CropFreeIcon from '@mui/icons-material/CropFree';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import IosShareIcon from '@mui/icons-material/IosShare';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NorthIcon from '@mui/icons-material/North';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import RemoveIcon from '@mui/icons-material/Remove';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';



export const IMG = ({
    src,
    className,
    style,
    onClick,
    color, // New color prop
}: {
    src: string;
    className?: string;
    style?: React.CSSProperties;
    onClick?: any;
    color?: string;
}) => {
    let IconComponent = Info; // Default SVG component

    const SvgList: { [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
        AppleStore,
        AlertSquare,
        AlertSquareG,
        AlertSquareT,
        AlertSquareR,
        ArrowDown,
        ArrowDownB,
        ArrowDownBlue,
        ArrowDownT,
        ArrowLeft,
        ArrowLeft2,
        ArrowLeftBa,
        ArrowRight,
        ArrowRight2,
        ArrowRightBa,
        ArrowRightTeal,
        ArrowUp,
        ArrowUpB,
        ArrowUpBlack,
        ArrowUpR,
        Chair,
        Clock,
        ClockSquare,
        Close,
        CloseBlack,
        CloseC,
        CloseOrange,
        Collection,
        CollectionOrange,
        Copy,
        Discord,
        DiscordB,
        DeleteW,
        Download,
        DrawerOpen,
        ExclamationBlack,
        ExclamationCircle,
        Explore,
        ExploreWhite,        
        Fail,
        FANSI,
        FANSIGO,
        FANSIGO_I,
        FANSIGO_LOGO,
        FANSIGO_LOGO_Gray,
        FANSIGO_S,
        FB,
        FBWhite,
        Files,
        Gear,
        Google,
        GooglePlay,
        Hourglass,
        Hourglass2,
        Home,
        HomeOrange,
        IG,
        IGGray,
        Info,
        InfoBlue,
        InfoGray,
        InfoTeal,
        InfoWhite,        
        Link,
        Linkedin,
        LinkTree,
        LinkTreeB,
        ListW,
        Location,
        LocationOrange,
        Lock,
        Logout,
        Logo,
        LogoFull,
        Map,
        Medale,
        MedaleOrange,
        Menu,
        Member,
        MemberG,
        MemberO,
        MetaBoom,
        NoEvent,
        OpenSea,
        Pause,
        Play,
        Question,
        QuestionTeal,
        Quote,
        QRCode,
        QRCodeBlack,
        Refresh,
        RisingStars,
        RoundCArrowLeft,
        RoundCArrowRight,        
        Scan,
        ShareOrange,
        ShareTicket,
        Shoeprint,
        Star,
        StarBlack,
        StarGray,
        StarEmpty,
        StarWhite,
        SeatClip,
        SeatPath,
        Ticket,
        TicketH,
        TicketOrange,
        Trashcan,
        TrophyBronze,
        TrophyGold,
        TrophySilver,
        Twitter,
        TwitterB,
        Wallet,
        X,

    }

    // ... your non-SVG images
    const ImgList: { [key: string]: string } = {
        MM,
        Safari,
    };

    if (SvgList[src]) {
        IconComponent = SvgList[src];
        return (
            <IconComponent
                className={className ? className : ''}
                onClick={onClick ? onClick : null}
                style={style ? style : {}}
                fill={color || 'currentColor'}
            />
        );
    } else if (ImgList[src]) {
        let imgSrc = ImgList[src];
        return (
            <img
                className={className ? className : ''}
                src={imgSrc}
                onClick={onClick ? onClick : null}
                style={style ? style : {}}
                alt={src}
            />
        );
    } else {
        // Handle the case where the src is not found
        return null;
    }
}

export const SVG = ({ src, sx, className, button, onClick }: { src: string, sx?: React.CSSProperties, className?: string, pure?: boolean, button?: boolean, onClick?: any }) => {
    if (!className) className = ''
    let icon = <PersonIcon className={className} sx={sx ? sx : {}} />;
    switch (src) {
        case 'Add':
            icon = <AddIcon className={className} sx={sx ? sx : {}} />;
            break;
        case 'ArrowRight':
            icon = <ArrowForwardIosIcon className={className} sx={sx ? sx : {}} />;
            break;
        case 'ArrowUp':
            icon = <KeyboardArrowUpIcon className={className} sx={sx ? sx : {}} />;
            break;
        case 'ArrowNorth':
            icon = <NorthIcon className={className} sx={sx ? sx : {}} />;
            break;
        case 'Calendar':
            icon = <CalendarMonthIcon className={className} sx={sx ? sx : {}} />;
            break;
        case 'Clock':
            icon = <AccessTimeOutlinedIcon className={className} sx={sx ? sx : {}} />;
            break;
        case 'Info':
            icon = <ErrorOutlineOutlinedIcon className={className} sx={sx ? sx : {}} />;
            break;
        case 'Location':
            icon = <LocationOnOutlinedIcon className={className} sx={sx ? sx : {}} />;
            break;
        case 'menu':
            icon = <MenuIcon className={className} sx={sx ? sx : {}} onClick={onClick ? onClick : null} />;
            break;
        case 'More':
            icon = <MoreHorizIcon className={className} sx={sx ? sx : {}} onClick={onClick ? onClick : null} />;
            break;
        case 'MoreV':
            icon = <MoreVertIcon className={className} sx={sx ? sx : {}} onClick={onClick ? onClick : null} />;
            break;
        case 'Remove':
            icon = <RemoveIcon className={className} sx={sx ? sx : {}} />;
            break;    
        case 'Check':
            icon = <CheckCircleIcon className={className} sx={sx ? sx : {}} />;
            break;  
        case 'CheckBox':
            icon = <CheckBoxIcon className={className} sx={sx ? sx : {}} />;
            break;  
        case 'CheckBoxOuter':
            icon = <CheckBoxOutlineBlankIcon className={className} sx={sx ? sx : {}} />;
            break;  
        case 'Cancel':
            icon = <CancelIcon className={className} sx={sx ? sx : {}} />;
            break;  
        case 'Trophy':
            icon = <EmojiEventsIcon className={className} sx={sx ? sx : {}} />;
            break;  
        case 'Share':
            icon = <IosShareIcon className={className} sx={sx ? sx : {}} />;
            break;           
        case 'Crop':
            icon = <CropFreeIcon className={className} sx={sx ? sx : {}} />;
            break;    
        case 'ZoomIn':
            icon = <ZoomInIcon className={className} sx={sx ? sx : {}} />;
            break;    
        case 'ZoomOut':
            icon = <ZoomOutIcon className={className} sx={sx ? sx : {}} />;
            break;     
        case 'Help':
            icon = <HelpIcon className={className} sx={sx ? sx : {}} />;
            break;   
        case 'Person':
            icon = <PeopleIcon className={className} sx={sx ? sx : {}} />;
            break;   
        case 'ShoppingCart':
            icon = <ShoppingCartOutlinedIcon className={className} sx={sx ? sx : {}} />;
            break;  
                           
    }
    
    if (button) return <IconButton >{icon}</IconButton >
    return icon;
}