import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { IMG } from '../lib/imgLib';
import Logo from './img/FANSI-GO-logo-white.svg';
import Aftee from './img/logo-aftee.svg';
import LinePay from './img/logo-linepay.svg';
import __ from '../lib/gettext';

export const FooterBlock = () => {
    let currPath = useLocation().pathname;
    const [url, setUrl] = useState('');
    const [currMenu, setMenu] = useState(1);

    useEffect(() => {
        if (currPath !== url) {            
            setUrl(currPath);
            setMenu(1); //reset first
            let currUrl = currPath.toLocaleLowerCase();
            if (currUrl.includes('/collection')) setMenu(3);
            if (currUrl.includes('/tickets')) setMenu(2);
        }
    }, [currPath, setUrl, url, setMenu])    

    return (
        <div className='fixed z-999 lg:relative bottom-0 left-0 w-full flex'>
            <div className='relative hidden lg:block w-full bg-gray-ed h-20 z-50 px-4'>
                <div className='relative section-md'>
                    <div className='aboslute left-0 top-0 w-full h-px bg-gray-b6' />
                    <div className='mt-7 flex'>
                        <div className='w-1/2 flex'>
                            <img className='mr-2' src={Logo} alt='logo' />
                            <p className='text-xs text-gray-b6 leading-none my-auto'>{`©${new Date().getFullYear()} FANSI Me Inc. All rights reserved.`}</p>
                        </div>
                        <div className='w-1/2 flex justify-end'>
                            <p className='text-xs text-[#9d9d9d] my-auto font-medium mr-2'>合作廠商</p>
                            <img src={LinePay} className='w-5 my-auto mr-2' alt='line' />
                            <img src={Aftee} className='w-5 my-auto mr-8' alt='aftee' />
                            <Link className=' my-auto mr-5' to='/service' target='_blank'>
                                <p className='text-xs text-gray-6d'>{__('Terms_of_Service')}</p>
                            </Link>
                            <Link className=' my-auto mr-5' to='/privacy' target='_blank'>
                                <p className='text-xs text-gray-6d'>{__('Privacy_Policy')}</p>
                            </Link>
                            <Link className=' my-auto mr-5' to='https://sweet-angle-e85.notion.site/FAQ-45d76bebcf1c4c02888dbc457ab98b3d' target='_blank'>
                                <p className='text-xs text-gray-6d'>{__('WEB_QA')}</p>
                            </Link>
                            <Link className=' my-auto mr-5' to='https://www.instagram.com/fansi_nft?igsh=MWZvZnJxZDBoMmgwMA==' target='_blank'>                            
                                <IMG src='IGGray' className='w-7 h-7 my-auto' />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='relative flex lg:hidden w-full h-full bg-white' style={{ zIndex: 900, boxShadow: '0 -4px 10px 0 rgba(0, 0, 0, 0.17)' }}>
                <div className='relative section-app h-[72px] flex'>
                    <div className='mt-1 w-full flex justify-evenly pb-2'>
                        <Link className='w-12 ' to='/'>
                            <IMG className='w-8 h-8 mx-auto' src={currMenu === 1 ? 'HomeOrange' : 'Home'} />
                            <p className={'w-full text-center Roboto text-xs font-medium ' + (currMenu === 1 ? 'text-quest-red' : 'text-[#9d9d9d]')}>{__('FOOTER_HP')}</p>
                        </Link>                        
                        <Link to='/collection/tickets' className='w-12 '>                        
                            <IMG className='w-8 h-8 mx-auto' src={currMenu === 2 ? 'TicketOrange' : 'TicketH'} style={{color : currMenu !== 2 ? '#767676':''}}/>
                            <p className={'mt-[2px] w-full text-center Roboto text-xs font-medium truncate ' + (currMenu === 2 ? 'text-quest-red' : 'text-[#9d9d9d]')}>{__('FGTR_My_Ticket')}</p>
                        </Link>
                        <Link className='w-12 ' to='/collection'>
                            <IMG className='w-8 h-8 mx-auto' src={currMenu === 3 ? 'MemberO' : 'MemberG'} style={{color: currMenu !== 3 ? '#767676':''}}/>
                            <p className={'w-full text-center Roboto text-xs font-medium ' + (currMenu === 3 ? 'text-quest-red' : 'text-[#9d9d9d]')}>{__('FOOTER_Collection')}</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterBlock;