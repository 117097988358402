import { Md5 } from 'ts-md5';
import html2canvas from 'html2canvas';
import CryptoJS from 'crypto-js';
import React from 'react';
import './style.css'

export function email_validate(email: string) {
  var re = {
    email: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  };

  if (!re.email.test(email)) {
    return false;
  } else {
    return true;
  }
}

export const convertPassword = (password: string): string => {
  return Md5.hashStr('FANSI_' + password + '_USER_!!!').toString();
};

export function IsInAppBrowser() {
  let inAppBrowser = false;
  var ua = navigator.userAgent || navigator.vendor;
  if (ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1 || ua.indexOf('Instagram') > -1 || ua.indexOf('Line') > -1 || ua.indexOf('Meta') > -1) inAppBrowser = true;
  return inAppBrowser;
}

export function convertDateTime(isoTimeString: string): { date: string, time: string } {
  const isoDate = new Date(isoTimeString);

  // Check if the input is a valid ISO time string
  if (isNaN(isoDate.getTime()))
    return { date: '', time: '' }

  const year = isoDate.getFullYear().toString().padStart(4, '0');
  const month = (isoDate.getMonth() + 1).toString().padStart(2, '0');
  const day = isoDate.getDate().toString().padStart(2, '0');

  const hours = isoDate.getHours().toString().padStart(2, '0');
  const minutes = isoDate.getMinutes().toString().padStart(2, '0');

  const dateString = `${year}/${month}/${day}`;
  const timeString = `${hours}:${minutes}`;

  return { date: dateString, time: timeString };
}

export function generateSerialCodes(codeLength: number, total: number) {
  const serialCodes: string[] = [];
  let count = 0;
  while (serialCodes.length < total && count < total + 1000) {
    const characters = Array.from({ length: codeLength - 2 }, () => {
      const isLetter = Math.random() < 0.7;
      if (isLetter) {
        return String.fromCharCode(Math.floor(Math.random() * 26) + 65); // Random letter
      } else {
        return (Math.floor(Math.random() * 9) + 1).toString(); // Random number between 1 and 9
      }
    });

    const firstLetter = String.fromCharCode(Math.floor(Math.random() * 26) + 65); // First character letter
    const lastLetter = String.fromCharCode(Math.floor(Math.random() * 26) + 65); // Last character letter

    let serialCode = firstLetter + characters.join('') + lastLetter;

    if (serialCodes.indexOf(serialCode) < 0) serialCodes.push(serialCode);
    count++;
  }

  return serialCodes;
};

export function convertDate(dateTimeString: string): string {
  const inputDate = new Date(dateTimeString);

  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getDate()).padStart(2, '0');

  const formattedDate = `${year}/${month}/${day}`;
  return formattedDate;
}

export function removeHtmlAttribute(html: string) {
  const htmlCode = `${html}`;
  // Use regular expression to remove data-renderer-start-pos attributes
  const modifiedHtmlCode = htmlCode.replace(/data-renderer-start-pos="[^"]*"/g, '');
  return modifiedHtmlCode;
}

export const encryptMessage = (originalMessage: string, symmetricKey: string) => {
  const encrypted = CryptoJS.AES.encrypt(originalMessage, symmetricKey).toString();
  return encrypted;
};

export const decryptMessage = (encryptedMessage: string, symmetricKey: string) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedMessage, symmetricKey).toString(CryptoJS.enc.Utf8);
  return decrypted;
};

export function ConfirmCodeGen(email: string, stageId: number, eventId: number, symKey: string) {
  let confirmCode = Md5.hashStr(email + eventId + stageId + symKey).toString().toUpperCase().substring(0, 10);
  return confirmCode
}

export function ChkLocalStorage(name: string, chkValue?: string) {
  let info = localStorage.getItem(name);
  if (info) {
    if (chkValue) return info === chkValue;    
    return true;
  }
  return false;
}

export function UpdLocalStorage(name: string, newValue: string) {
  localStorage.setItem(name, newValue);
}

export const encodeTicketIds = (showId: number, ticketId: number, userId: number): string => {
  const showIdStr = showId.toString();
  const ticketIdStr = ticketId.toString();
  const userIdStr = userId.toString();

  const showIdLen = showIdStr.length;
  const ticketIdLen = ticketIdStr.length;
  const userIdLen = userIdStr.length;

  const lengthStr = `${showIdLen}${ticketIdLen}${userIdLen}`;

  const combinedStr = showIdStr + ticketIdStr + userIdStr;

  if (combinedStr.length + 3 > 25) {
    throw new Error("The combined length of the IDs and their lengths must not exceed 20 characters");
  }

  const paddedStr = combinedStr.padEnd(22, '0');
  return paddedStr + lengthStr;
};

export const decodeTicketString = (encodedStr: string): { showId: number, ticketId: number, userId: number } => {
  if (encodedStr.length !== 25) return { //invalid
    showId: -1,
    ticketId: -1,
    userId: -1,
  };

  const showIdLen = parseInt(encodedStr.charAt(22));
  const ticketIdLen = parseInt(encodedStr.charAt(23));
  const userIdLen = parseInt(encodedStr.charAt(24));

  const showIdStr = encodedStr.substring(0, showIdLen);
  const ticketIdStr = encodedStr.substring(showIdLen, showIdLen + ticketIdLen);
  const userIdStr = encodedStr.substring(showIdLen + ticketIdLen, showIdLen + ticketIdLen + userIdLen);

  return {
    showId: parseInt(showIdStr),
    ticketId: parseInt(ticketIdStr),
    userId: parseInt(userIdStr),
  };
};

const PR_SecretKey = 'FANSI_PR_KEY';
const charArray = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';

function shiftString(str: string, shiftNumber: number, reverse = false): string {
  return str.split('').map(char => {
    const index = charArray.indexOf(char);
    if (index !== -1) {
      let shiftedIndex = reverse
        ? (index - shiftNumber) % charArray.length
        : (index + shiftNumber) % charArray.length;
      if (shiftedIndex < 0) shiftedIndex += charArray.length; // Handle negative shifts
      return charArray[shiftedIndex];
    }
    return char; // Return original char if not found in custom array
  }).join('');
}

export interface Ticket {
  uid: string;
  code: string;
  hash: string;
}

export function generateTicket(uid: number, serialCode: string): Ticket {
  // Add 128 to uid for disguise
  uid += 128;
  // Generate HMAC hash with uid and serialCode
  const data = `uid=${uid}&code=${serialCode}`;
  let hash = CryptoJS.HmacSHA256(data, PR_SecretKey).toString(CryptoJS.enc.Hex);

  // Convert hash to uppercase
  hash = hash.toUpperCase();

  // Use the 2nd character of the hash to derive the shift character
  const shiftChar = hash.charAt(1);
  // Convert shiftChar to shiftNumber based on array length
  const shiftNumber = parseInt(shiftChar, 16) % charArray.length;

  // Shift UID and serial code
  const uidShifted = shiftString(uid.toString(), shiftNumber);
  const serialCodeShifted = shiftString(serialCode.toUpperCase(), shiftNumber);

  // Use only the first 5 and last 5 characters of the hash
  const shortHash = hash.substring(0, 5) + hash.substring(hash.length - 5);

  // Return ticket object with encoded URL parameters
  return {
    uid: uidShifted,
    code: serialCodeShifted,
    hash: shortHash
  };
}

export function decodeTicket(uidShifted: string, serialCodeShifted: string, shortHash: string): { uid: number, serialCode: string } | null {
  // Use the 2nd character of the shortHash to derive the shift character
  const shiftChar = shortHash.charAt(1);
  // Convert shiftChar to shiftNumber based on array length
  const shiftNumber = parseInt(shiftChar, 16) % charArray.length;

  // Reverse shift UID and serial code
  const uid = shiftString(uidShifted, shiftNumber, true);
  const serialCode = shiftString(serialCodeShifted, shiftNumber, true);

  // Generate HMAC hash with uid and serialCode
  const data = `uid=${uid}&code=${serialCode}`;
  let expectedHash = CryptoJS.HmacSHA256(data, PR_SecretKey).toString(CryptoJS.enc.Hex);

  // Convert expected hash to uppercase
  expectedHash = expectedHash.toUpperCase();

  // Use only the first 5 and last 5 characters of the expected hash
  const expectedShortHash = expectedHash.substring(0, 5) + expectedHash.substring(expectedHash.length - 5);

  // Verify hash integrity
  if (shortHash !== expectedShortHash) return null;

  // Subtract 128 from uid to restore the original value
  const origin_uid = parseInt(uid) - 128;

  // Return decoded ticket parameters
  return {
    uid: origin_uid,
    serialCode
  };
}

export function MetaPixel(eventId: number, eventName: string = 'PageView', extraInfo: { [key: string]: any } = {}) {
  const PixelCode: { [k: number]: string } = {
    12: '1240952003562889',
    19: '8304325142922499',
    200001: '455062410584323',
    210001: '1128975881957587',
  };
  try {
    if (PixelCode[eventId]) {
      let trackingCode = PixelCode[eventId];
      (function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

      fbq('init', trackingCode);
      fbq('track', eventName, extraInfo); // Pass extra info as the third parameter
    }
  } catch (err) {
    console.warn(err);
  }
}

export async function CaptureImg(
  captureRef: React.RefObject<HTMLDivElement>,
  fileName: string = 'MyTicket',
  quality: number = 0.85,
  imgType: number = 1,
  cssClass?: string // Optionally pass CSS class for adjustment
) {
  if (!captureRef.current) return;

  // Apply the CSS class if provided
  if (cssClass) {
    captureRef.current.classList.add(cssClass);
  }

  // Capture the image
  const canvas = await html2canvas(captureRef.current, { useCORS: true, backgroundColor: null });

  // Remove the CSS class after capture to revert back to normal
  if (cssClass) {
    captureRef.current.classList.remove(cssClass);
  }

  // Create the image data
  const image = imgType === 1 ? canvas.toDataURL('image/webp', quality) : canvas.toDataURL('image/png');

  // Create a link element to download the image
  const link = document.createElement('a');
  link.href = image;
  link.download = `${fileName}.${imgType === 1 ? 'webp' : 'png'}`;
  link.click();
}

export function formatTicketDate(startDate: string, endDate: string): string {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Get the year, month, and day for both dates
  const startYear = start.getFullYear();
  const startMonth = start.getMonth() + 1; // Months are zero-indexed in JS
  const startDay = start.getDate();

  const endMonth = end.getMonth() + 1; // Months are zero-indexed in JS
  const endDay = end.getDate();

  // Format as "YYYY.M.D - M.D"
  return `${startYear}.${startMonth}.${startDay} - ${endMonth}.${endDay}`;
}

// Function to encode two variable-length digit strings into an 8-character string using a secret key
export function previewEncode(input1: string, input2: string): string {
  // Define the alphabet A-Z
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const secretKey = 95279487;
  // Helper function to map a number to an alphabet character
  function numberToAlphabetChar(num: number): string {
    return alphabet[num % alphabet.length]; // Ensures we wrap around the alphabet
  }

  // Helper function to encode a number using a secret key (XOR-based obfuscation)
  function applySecretKey(num: number): number {
    return num ^ secretKey; // XOR operation for obfuscation
  }

  // Ensure input strings are numeric and within the valid length range
  if (!/^\d{1,8}$/.test(input1) || !/^\d{1,8}$/.test(input2)) {
    throw new Error('Input strings must be numeric and between 1 to 8 digits long.');
  }

  // Combine the two strings into one long number, ensuring it handles varying lengths
  const combined = parseInt(input1 + input2, 10);

  // Apply the secret key using XOR to obfuscate the combined number
  const obfuscatedNumber = applySecretKey(combined);

  // Convert the obfuscated number to an 8-character string using A-Z
  let encodedString = '';
  let tempNumber = obfuscatedNumber;

  // Generate 8 capital letters based on the obfuscated number
  for (let i = 0; i < 8; i++) {
    encodedString = numberToAlphabetChar(tempNumber % 26) + encodedString;
    tempNumber = Math.floor(tempNumber / 26);
  }

  return encodedString;
}

export function encodeRedeem(showId: number, userId: number, orderId: number, nonce: number): string {
  // Determine the maximum required padding length for the IDs
  const maxDigits = Math.max(
    showId.toString().length,
    userId.toString().length,
    orderId.toString().length,
    6
  );
  const paddingLength = Math.min(Math.max(maxDigits, 6), 10);

  // Use the first digit to represent the padding length
  const prefix = paddingLength.toString();

  // Pad showId, userId, and orderId to the determined length
  const paddedShowId = showId.toString().padStart(paddingLength, '0');
  const paddedUserId = userId.toString().padStart(paddingLength, '0');
  const paddedOrderId = orderId.toString().padStart(paddingLength, '0');

  // Pad nonce to always be 3 digits
  const paddedNonce = nonce.toString().padStart(3, '0');

  return `${prefix}${paddedShowId}${paddedUserId}${paddedOrderId}${paddedNonce}`;
}

export function decodeRedeem(encodedString: string): { showId: number; userId: number; orderId: number; nonce: number; } {
  // The first digit determines the padding length
  const paddingLength = parseInt(encodedString[0], 10);

  // Extract showId, userId, orderId, and nonce based on the padding length
  const showId = parseInt(
    encodedString.slice(1, 1 + paddingLength),
    10
  );
  const userId = parseInt(
    encodedString.slice(1 + paddingLength, 1 + 2 * paddingLength),
    10
  );
  const orderId = parseInt(
    encodedString.slice(1 + 2 * paddingLength, 1 + 3 * paddingLength),
    10
  );
  const nonce = parseInt(
    encodedString.slice(1 + 3 * paddingLength),
    10
  );

  return { showId, userId, orderId, nonce };
}

export const GAEventTrack = (
  eventName: string,
  eventData: Record<string, any>
) => {
  if (typeof window.gtag === "function") {
    // Use the gtag function to track the event
    window.gtag("event", eventName, {
      ...eventData, // Spread the event data into the payload
      page_location: window.location.href // Automatically add the current page URL
    });
  } else {
    console.warn("GA tracking function not found");
  }
};